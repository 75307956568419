import { useEffect, useState } from "react";
import ChatGPTFormatter from "./ChatGptFormatter";
import Helpers from "../Config/Helpers";

const Message = ({ message, isWriting = false, isBot = false }) => {
  const [imagePath, setImagePath] = useState("");
  const [formData, setFormData] = useState(""); // State to store form data

  const getImage = () => {
    if (isBot) {
      setImagePath("/app/chat icon 1.png");
    } else {
      let user = Helpers.getItem("user", true);

      if (user) {
        if (user.token && user.profile_pic) {
          setImagePath(Helpers.serverImage(user.profile_pic, user.token));
        } else {
          setImagePath("/app/guest.png");
        }
      } else {
        setImagePath("/app/guest.png");
      }
    }
  };

  useEffect(() => {
    getImage();

   
    const storedFormData = isBot ? localStorage.getItem("formData") : null;
    if (storedFormData) {
     
      setFormData(JSON.parse(storedFormData));
    }
  }, [isBot]);

  return (
    <div className="tyn-qa-item">
      <div className="tyn-qa-avatar"></div>
      <div
        className={`tyn-qa-message tyn-text-block ${
          isBot ? "bot-msg" : "human-msg"
        }`}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="tyn-media tyn-size-md m-1 ">
            {imagePath && <img src={imagePath} alt="" />}
          </div>
          <h1 className={`bot-name m-2 text-black ${isBot ? "bot-name" : "user-name"}`}>
            {" "}
            <small>{isBot ? "Legal Master" : "Me"}</small>{" "}
          </h1>
        </div>
        <hr></hr>

        { formData && (
          <div className="form-data-container ">
            {formData.state && <pre>state: {formData.state}</pre>}
            {formData.country && <pre>Country: {formData.country}</pre>}
            {formData.details && <pre>details: {formData.details}</pre>}
            {formData.plaintiffOrDefendant && (
              <pre>plaintiff Or Defendant: {formData.plaintiffOrDefendant}</pre>
            )}
          </div>
        )}

        <ChatGPTFormatter response={message} writing={isWriting} />
      </div>
    </div>
  );
};

export default Message;
