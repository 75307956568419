import { useContext, useEffect, useRef } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Helpers from "../../Config/Helpers";
import { ChatsContext } from "../../Contexts/ChatsContext";
import axios from "axios";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes, faHeart } from "@fortawesome/free-solid-svg-icons";
import NavbarContext from "../../Contexts/NavbarContext";
import { GiInjustice } from "react-icons/gi";
import { CSSTransition } from 'react-transition-group';
import { FaRegEye } from "react-icons/fa";
import { FaLongArrowAltLeft, FaLongArrowAltRight  } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { RiHistoryLine } from "react-icons/ri";
import { BiSolidDollarCircle } from "react-icons/bi";
import { IoPeopleCircle } from "react-icons/io5";

const userLinks = [
  {
    name: 'Dashboard',
    icon: <MdDashboard className="mx-2 text-warning"/>,
    to: "/user/dashboard"
  },
  {
    name: 'Chat History',
    icon: <RiHistoryLine className="mx-2 text-warning"/>,
    to: "/user/chat-history"
  },
  {
    name: 'Pricing Plans',
    icon: <BiSolidDollarCircle className="mx-2 text-warning"/>,
    to: "/user/pricing"
  },
  {
    name: 'Teams',
    icon: <IoPeopleCircle className="mx-2 text-warning"/>,
    to: "/user/coming-soon"
  },
];

const UserLayout = () => {
  const { chats, setChats, showChat, setShowChat } = useContext(ChatsContext);
  const location = useLocation();
  const navigate = useNavigate();
  // Self Made for dealing with side bar
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [sideBarIsOpen, setSideBarIsOpen] = useState(false)
  const [hasSideBar, setHasSideBar] = useState(true);
  const Navbar = useContext(NavbarContext);
  const NavRef = useRef(null);

  const handleExpandedSideBar = () => {
    if(sideBarIsOpen){
      setHasSideBar(true);
      Navbar.state.hasSideBar = true;
    }else {
    setHasSideBar(false);
    Navbar.state.hasSideBar = false;
    }
    setSideBarIsOpen(!sideBarIsOpen)
    Navbar.state.sideBarIsOpen = (!sideBarIsOpen);
    Navbar.toggle();
  } 


  const deleteChat = (chatId) => {
    axios.get(`${Helpers.apiUrl}chat/delete/${chatId}`, Helpers.authHeaders).then((response) => {
      Helpers.toast("success", response.data.message);
      setChats(response.data.chats);
    });
  };

  // toggle function
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [showToggleButton, setShowToggleButton] = useState(false);
  const handleResize = () => {
    setShowToggleButton(window.innerWidth <= 768); // Set breakpoint as needed
  };

  useEffect(() => {
    Helpers.toggleCSS();
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [location.pathname]);


  const gotoChat = (url) => {
    setShowChat(true);
    navigate(url);
  };

  const logout = () => {
    localStorage.clear();

    window.location.href = "/auth/login";
    Helpers.toast("success", "Logged out successfully");
  };

  const isLoggedIn = localStorage.getItem("token");


  useEffect(() => {
    if(!(innerWidth > 500)){
      setHasSideBar(false)
      setSideBarIsOpen(true)
      Navbar.state.hasSideBar = false;
      Navbar.state.isSidebarOpen = true;
    }
    if(location.pathname === '/user/mockup-trial')
    {
      setHasSideBar(false)
      setSideBarIsOpen(true)
    }
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };
    handleResize();
  }, []);

  const handleMockupTrial = () => {
    let chatId = Helpers.generateChatId();
    navigate(`/user/mockup-trial/${chatId}`)
  }

  useEffect(() => {
    Helpers.toggleCSS();
  }, [location.pathname]);

  return (
    <div className="tyn-root"  style={{ background: "#0C1B44" }}>
      <nav className={` ${(sideBarIsOpen && (innerWidth < 500)) ? "tyn-appbar": ""} `}  style={{ background: "#0C1B44" }}>
        <div className="tyn-appbar-wrap">
          <div className="tyn-appbar-logo" style={{ position: "relative" }}>
            <Link className="tyn-logo" to={"/"}>
              <img src="/app/logo-duo.png" alt="logo" />
            </Link>
          </div>
          <div
            className="nav-icon-container pointer"
          >

          <button 
          className="btn btn-prim mx-4"
          onClick={handleMockupTrial}
          >
            <GiInjustice 
            style={{width: "1.5rem"}} 
            />
            Mock up Trial
          </button>

          <div style={{display: "inline"}}
          className="pointer" 
          onClick={handleExpandedSideBar}>
            {sideBarIsOpen ?
            <FaLongArrowAltRight className="nav-icon"/>
            // <i class="fa-duotone fa-arrow-right nav-icon"></i>
            :
            <FaLongArrowAltLeft className="nav-icon"/>
            // <i class="fa-duotone fa-arrow-left nav-icon"></i>
          }
          </div>
          </div>
        </div>
      </nav>
      {/* ${showChat ? 'w0' : 'has-aside-base'} */}
      
      <div
        className={`tyn-content-full-height tyn-chatbot tyn-chatbot-page 
        ${hasSideBar ? "has-aside-base tyn-content": "col-md-12"}`}
        style={hasSideBar ? 
        { background: "#0C1B44", overflowX: "hidden" }
        : {backgroundColor: "white", overflowX: "hidden"}} 
        // style={{background: "#0C1B44", overflowX: "hidden"}}
        >

          {/* <CSSTransition
            in={hasSideBar}
            nodeRef={NavRef}
            timeout={300}
            classNames="alert"
            unmountOnExit
            > */}
        <div 
        // ref={NavRef}
        className={`${hasSideBar ? "tyn-aside tyn-aside-base" : "d-none bg-white"}`} 
        // className="tyn-aside tyn-aside-base"
        style={{ background: "#0C1B44" }}
        >

          <div className="tyn-aside-head">
            <div className="tyn-aside-head-text" >
              <h3 className="tyn-aside-title tyn-title white" >
                Legal Master AI
              </h3>
              <span className="tyn-subtext white-sub">
                {chats.length}+ Conversations{" "}
              </span>
            </div>
            <div className="tyn-aside-head-tools" >
              <ul className="tyn-list-inline gap gap-3">
                <li>
                  <div
                    className="btn btn-icon btn-light btn-md btn-pill btn-newchat pointer"
                    onClick={() => gotoChat("/user/dashboard")}
                    style={{ background: "#0C1B44"  }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-plus-lg"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                      />
                    </svg>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="tyn-aside-body" data-simplebar   style={{ background: "#0C1B44" }}>
            <ul className="tyn-aside-list">
              {userLinks.map((link, index) => {
                return (
                  <li
                  key={index}
                  className="tyn-aside-item tyn-aside-item-bubbly js-toggle-main chat-item my-2"
                >
                  <Link to={link.to} className="content white w-100">
                  <div className="tyn-media-group">
                    <div
                      className="tyn-media-col pointer"
                    >
                      <div className="d-flex justify-content-start align-items-center fw-bold fs-4">
                      {link.icon} 
                      {link.name}
                      </div>
                    </div>
                  </div>
                  </Link>
                </li>
                );
              })}
            </ul>
          </div>
          <div className="tyn-aside-foot">
            <div className="w-100">
              <div className="row gx-3">
                <div className="col-6">
                  {isLoggedIn ? (
                    <button
                      className="btn btn-dark tyn-size-lg w-100 flex-column py-2 pt-3"
                      onClick={logout}
                      style={{  background : "#c48219" }}
                      
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-box-arrow-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 12.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zM.146 8.354a.5.5 0 1 1 .708 0L4 11.5V8a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1H4.707l-3.146 3.146a.5.5 0 1 1-.708-.708L4.293 8.5H.5a.5.5 0 0 1-.5-.5z"
                        />
                        <path
                          fillRule="evenodd"
                          d="M13.5 3A1.5 1.5 0 0 1 15 4.5v7a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 3 11.5v-7A1.5 1.5 0 0 1 4.5 3h9zM4 4.5v7a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z"
                        />
                      </svg>
                      <span
                        className="small text-nowrap mt-n1"
                        style={{ color: "black"  }}
                  
                      >
                        Logout
                      </span>
                    </button>
                  ) : (
                    <Link
                      to="/auth/register"
                      className="btn btn-dark tyn-size-lg w-100 flex-column py-2 pt-3"
                      style={{  background : "#c48219" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-person-plus"
                        viewBox="0 0 16 16"
                      >
                        <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        <path
                          fillRule="evenodd"
                          d="M12.5 5.5a.5.5 0 0 1 .5.5v1.5h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H12V5.5a.5.5 0 0 1 .5-.5z"
                        />
                      </svg>
                      <span
                        className="small text-nowrap mt-n1"
                        style={{ textDecoration: "none", color: "black" ,  }}
                      >
                        Create Account
                      </span>
                    </Link>
                  )}
                </div>

                <div className="col-6">
                  <Link
                    to="/auth/Payments"
                    className="btn btn-dark tyn-size-lg w-100 flex-column py-2 pt-3 "
                    style={{ background : "#c48219"  }}
                  >
                    <FontAwesomeIcon icon={faHeart} />
                    <span
                      className="small text-nowrap mt-n1"
                      style={{ textDecoration: "none", color: "black"  }}
                    >
                      Donate
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* </CSSTransition> */}
        <Outlet />
      </div>
    </div>
  );
};

export default UserLayout;
