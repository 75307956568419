import React, { useState, useContext, useEffect, useReducer } from 'react'
import { ChatsContext } from "../../Contexts/ChatsContext";
import useClipboard from "react-use-clipboard";
import { FaCheck } from 'react-icons/fa';
import { MdOutlineContentCopy } from 'react-icons/md'
import { IoClose } from 'react-icons/io5';
import { IoPersonSharp } from "react-icons/io5";
import { FaPlusCircle ,FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import axios from 'axios';
import Helpers from '../../Config/Helpers';
import ExpirationAlert from '../../Components/ExpirationAlert';

const defaultUserLimit = {
    id: 0,
    team: 0,
    name: "",
    email: "",
    no_words: ""
};

export default function Teams() {
    const [user,setUser] = useState();
    const { setChats, showChat } = useContext(ChatsContext);
    const [showInvite, setShowInvite] = useState(false);
    const [showJoin, setShowJoin] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [teamId, setTeamId] = useState('');
    const [team, setTeam] = useState([]);
    const [wordLimit, setWordLimit] = useState(defaultUserLimit);
    const [isDisable, setIsDisable] = useState(true)
    const [errors, setErrors] = useState({});
    const [isCopied, setCopied] = useClipboard(
        user && user.team_id
          ? `${window.location.origin}/auth/register/team/${Helpers.encryptNumber(user.team_id)}`
          : '',
        {
          successDuration: 1000,
        }
      );
    const [copyTeamId, setCopyTeamId] = useClipboard(
        user && user.team_id
          ? `${Helpers.encryptNumber(user.team_id)}`
          : '',
        {
          successDuration: 1000,
        }
      );
      

    const getTeams = () => {
        setIsDisable(true);
        const data = {team_id: user.team_id};
            axios.get(`${Helpers.apiUrl}team/get`, {
                params: data,
                headers: Helpers.authHeaders.headers
            }).then((res)=>{
                setIsDisable(false);
                setTeam(res.data.teams);
            }).catch(err=>{
                setIsDisable(false);
                Helpers.toast("error", "An Error occured");
                console.log(err);
            })
    };

    const handleSaveLimit = (event) => {
        setIsDisable(true);
        event.preventDefault(); // Prevent default form submission
        axios.post(`${Helpers.apiUrl}team/save`, wordLimit, Helpers.authHeaders).then((res)=>{
            Helpers.toast("success", res.data.message)
            setWordLimit(defaultUserLimit);
            setIsDisable(false);
            setShowInvite(!showInvite);
        }).catch(err=>{
            Helpers.toast('error', err.message)
        })
    }
    
    const toggleInvite = () => {
        setShowInvite(!showInvite)
    }

    const toggleJoin = () => {
        setShowJoin(!showJoin);
    }
    
    const handleCancelForm = () => {
        setShowForm(!showForm)
    }


    const handleShowForm = (selectedUser) => {
        setShowForm(true);
        setWordLimit(selectedUser)
    }

    const handleJoinTeam = () => {
        setIsDisable(true);
        const data = {team_id: Helpers.decryptNumber(teamId)};
        axios.post(`${Helpers.apiUrl}team/add-member`, data, Helpers.authHeaders).then((res)=>{
            setTeam(res.data.teams);
            Helpers.toast("success",res.data.message);
            setIsDisable(false);
            setShowJoin(!showJoin);
            Helpers.setItem("user", res.data.user, true);
        }).catch((err)=>{
            Helpers.toast('error', err.response.data.message);
            setIsDisable(false);
            console.error(err);
        })
    }

    const handleDeleteUser = (user) => {
        setIsDisable(true);
        axios.post(`${Helpers.apiUrl}team/delete/${user.id}`, {},Helpers.authHeaders).then((res)=>{
            setTeam(res.data.teams);
            setIsDisable(false);
            Helpers.toast("success",res.data.message);
        }).catch((err)=>{
            setIsDisable(false);
            Helpers.toast('error', err);
            console.error(err);
        })
    }

    useEffect(()=>{
        setUser(JSON.parse(localStorage.getItem('user')));
    },[]);

    useEffect(()=>{
        if(user){
            getTeams();
        }
    }, [user])

    return (
        <div
            className={`tyn-main ${showChat ? "main-shown" : ""}`}
            id="tynMain"
            style={{ overflow: "auto", backgroundColor: "white" }}
        >
            <div className="container-fluid">
                <div className="mt-4">
                    <ExpirationAlert />
                </div>
                <div className='d-flex flex-row justify-content-between align-items-center'>
                    <div className="tyn-text-block m-2">
                        <h1 className="mt-3 black">Teams</h1>
                        <p className="white-sub text-muted">Create Team and Invite Others</p>
                    </div>

                    {(!showInvite && !showForm && (user && user.is_manager == 1) ) && (<button className='btn btn-light m-5' onClick={toggleInvite}>Invite</button>)}
                    {(!showInvite && !showForm && (user && user.is_manager == 0 && (user.team_id == 0)) ) && (<button className='btn btn-light m-5' onClick={toggleJoin}>Join</button>)}

                </div>

                {/* Invitation Form */}

                    {(showInvite && !showForm && !showJoin) && (<div className='card col-md-12'>
                        <div className='d-flex col-md-12 justify-content-between mt-2'>
                        <h5 className='form-label mx-2'>Invite</h5>
                            <IoClose
                                className='mx-2'
                                style={{ fontSize: "1.2rem" }}
                                onClick={toggleInvite} />

                        </div>
                        <div className='card-body shadow-none shadow-bottom-right'>
                            <div className="form-group">
                            <label className="form-label">Registration Via Link: </label>
                                <div className="form-control-wrap d-flex flex-row justify-content-center align-items-center">
                                {(user && user.team_id) && (
                                    <input
                                        type="text"
                                        value={`${window.location.origin}/auth/register/team/${Helpers.encryptNumber(user.team_id)}`}
                                        disabled={true}
                                        className="form-control" />
                                        )}
                                    <button className='btn btn-light m-1' onClick={setCopied}>
                                        {isCopied ? <FaCheck /> : <MdOutlineContentCopy />}
                                    </button>
                                </div>
                                <div className="form-control-wrap d-flex flex-row justify-content-center align-items-center">
                                {(user && user.team_id) && (
                                    <input
                                        type="text"
                                        value={`${Helpers.encryptNumber(user.team_id)}`}
                                        disabled={true}
                                        className="form-control" />
                                        )}
                                    <button className='btn btn-light m-1' onClick={setCopyTeamId}>
                                        {copyTeamId ? <FaCheck /> : <MdOutlineContentCopy />}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>)}

                    {/* Join Form */}
                    {(showJoin && !showInvite && !showForm) && (<div className='card col-md-12'>
                        <div className='d-flex col-md-12 justify-content-between mt-2'>
                        <h5 className='form-label mx-3'>Join</h5>
                            <IoClose
                                className='mx-2'
                                style={{ fontSize: "1.2rem" }}
                                onClick={toggleJoin} />
                        </div>
                        <div className='card-body shadow-none shadow-bottom-right'>
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Team ID</label>
                                <input 
                                value={teamId}
                                class="form-control"
                                id="number" 
                                aria-describedby="number"
                                onChange={(e)=>setTeamId(e.target.value)} 
                                />
                            </div>
                            <div className='d-flex flex-row justify-content-end mx-2'>
                            <button className='btn btn-prim' onClick={handleJoinTeam}>Join</button>
                            </div>
                        </div>


                    </div>)}


                {(!showInvite && !showForm && !showJoin) && (<div class="nk-block">
                    <div class="card shadown-none">
                        <div class="card-body">
                            <div class="row g-3 gx-gs">
                                <div className="col-md-12">
                                    <div className="table-container" style={{ maxHeight: "62vh", overflowY: "auto" }}>
                                        {user && (<table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Sr. #</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    {(user.is_manager === 1) ? (<th>Word Limit</th>) : (<th>Role</th>)} 
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* Actual Data */}
                                                        {(user.is_manager === 1) ? 
                                                        // If the user is Manager
                                                        (<>
                                                            {team.map((member,index) => (
                                                                <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{member.name}</td>
                                                            <td>{member.email}</td>
                                                            <td>{member.no_words}</td>
                                                            <td className='d-flex flex-row justify-content-end'>
                                                                 <div>
                                                                 { (!member.is_manager == 1) ? (
                                                                    <>
                                                                 <button className="btn btn-outline-prim btn-sm m-1" onClick={()=>handleShowForm(member)}>
                                                                        <FaRegEdit /><span className="ml5" >Edit</span>
                                                                    </button>
                                                                    <button className="btn btn-outline-second btn-sm ml10 m-1" onClick={()=>handleDeleteUser(member)}>
                                                                        <MdDeleteOutline /><span className="ml5">Delete</span>
                                                                    </button>
                                                                    </>) : (
                                                                    <td className='d-flex flex-row justify-content-end'>
                                                                    <div>
                                                                        <button className="btn btn-outline-prim btn-sm m-1" onClick={()=>handleShowForm(member)}>
                                                                            <FaRegEdit /><span className="ml5" >Edit</span>
                                                                        </button>
                                                                        <button className="btn btn-outline-prim btn-sm m-1" disabled={true}>
                                                                            <MdDeleteOutline />
                                                                            <span className="ml5">Delete</span>
                                                                        </button>
                                                                    </div>
                                                                    </td>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                        </>) : 
                                                        // If the Team Exist but is not a Manager
                                                        (user.team != 0) ?

                                                        (<>
                                                            {team.map((member,index) => (
                                                                <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{member.name}</td>
                                                            <td>{member.email}</td>
                                                            <td className='d-flex flex-row'>
                                                                <p className='text-muted'>
                                                                    Manager
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                        </>)
                                                        :
                                                        // If no Team exists
                                                        <></>
                                                        }
                                                        {(user && user.is_manager == 0 && user.team_id == 0) && (
                                                            <p className='text-muted mx-1 w-100'>
                                                            No Teams Exist
                                                            </p>
                                                        )}
                                            </tbody>
                                        </table>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}

                {(!showInvite && showForm && !showJoin) && (
                          <>
                            <div class="card shadown-none">
                              <div class="card-body">
                                <div class="row g-3 gx-gs">
                                  <form>

                                  <div class="col-md-12">
                                    <div class="form-group">
                                      <label class="form-label">Word Limit</label>
                                      <div class="form-control-wrap">
                                          <input type="text" 
                                          value={wordLimit.no_words} 
                                          onChange={e=> setWordLimit({...wordLimit, no_words: e.target.value})}
                                          class="form-control" placeholder="Enter the Word Limit for this user" />
                                          <small className="text-danger">
                                              { errors.button_text ? errors.button_text[0] : '' }
                                          </small>
                                          </div>
                                      </div>
                                    </div>

                                    <div className='d-flex flex-row justify-content-end my-3'>
                                      
                                      <button 
                                      className='btn btn-prim mx-1'
                                      onClick={handleSaveLimit}
                                    > Save
                                      </button>
                                      
                                      <button 
                                      className='btn btn-outline-second mx-1' 
                                      onClick={handleCancelForm}
                                    >
                                        Cancel
                                      </button>

                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

            </div>
        </div>
    )
}
