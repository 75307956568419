import { useContext, useEffect, useState } from "react";
import Helpers from "../../Config/Helpers";
import axios from "axios";
import { ChatsContext } from "../../Contexts/ChatsContext";
import { useNavigate } from "react-router-dom";
import PageLoader from '../../Components/Loader/PageLoader';
import { Link } from "react-router-dom";
import ExpirationAlert from "../../Components/ExpirationAlert";

const UserDashboard = () => {
  const { setChats, showChat } = useContext(ChatsContext);
  const [queries, setQueries] = useState([]);
  const [categories, setCategories] = useState([])
  const [categorizedQueries, setCategorizedQueries] = useState([]);
  const [categorizedQueriesKeys, setCategorizedQueriesKeys] = useState([])
  const navigate = useNavigate();

  const defineUser = () => {
    const user = Helpers.getItem("user", true);
    if (user) {
      Helpers.setItem("id", user.id);
    } else {
      axios.get(`https://api.ipify.org/?format=json`).then((response) => {
        Helpers.setItem("id", response.data.ip);
      });
    }
    getChats();
  };

  const getChats = () => {
    let userId = Helpers.getItem("id");
    axios.get(`${Helpers.apiUrl}chat/all/${userId}`, Helpers.authHeaders).then((response) => {
      setChats(response.data);
    });
  };

  const initChat = (message = "") => {
    if (message) {
      Helpers.setItem("message", message);
    }
    let chatId = Helpers.generateChatId();
    navigate(`/user/chat/${chatId}`);
  };

  const getQueries = () => {
    axios.get(`${Helpers.apiUrl}queries/all`, Helpers.authHeaders).then(response => {
        setQueries(response.data);
    });
  }

  const getCategories = () => {
    axios.get(`${Helpers.apiUrl}categories/all`, Helpers.authHeaders).then(response => {
        setCategories(response.data);
    });
  }

  function compareQueriesAndCategories(queries, categories) {
    const result = {};
    queries.forEach((query) => {
      const category = categories.find((category) => category.id == query.category_id);
      if (category) {
        const categoryName = category.name;
        const queryText = query.query;
        if (!result[categoryName]) {
          result[categoryName] = [];
        }
        result[categoryName].push({ query: queryText, id: query.category_id });
      }
    });
    setCategorizedQueries(result);
    const keys = Object.keys(result)
    setCategorizedQueriesKeys(keys);
    return result;
  }


  useEffect(() => {
    defineUser();
    getQueries();
    getCategories();
  }, []);

  useEffect(()=> {
    const result = compareQueriesAndCategories(queries, categories);
  }, [queries,categories])
  

  const documentDraftingRequests = [
    "My neighbor's dog ate my mail. What should I do?",
    "A lawsuit has been filed against me, what should I do?",
    "How do I deal with a debt collector who won't stop calling me?",
    "My landlord wants to evict me, I don't have money to pay rent.",
    
  ];

  const legalAdvice = [
    "Produce a template for a Legal Malpractice Lawsuit",
    "Draft  a lawsuit using my information( Ask for additional information as a followup-",
    "A Lawsuit has been filed against me, help me draft an Answer.",
    "Draft a motion for Summary Judgment",
    
  ];

  return (
    <div
      className={`tyn-main ${showChat ? "main-shown" : ""}`}
      id="tynMain"
      style={{ overflow: "auto", backgroundColor: "white" }}
    >
      <div className="tyn-section">
        {categories.length > 0 ? (<div className="container-fluid">

        <ExpirationAlert />

          <div className="tyn-text-block text-center pb-4 pb-lg-5">
            <a className="tyn-logo w100px" href="index.html">
              <img src="/app/logo-duo.png" className="w100px" alt="logo" />
            </a>
            <h1 className="mt-3 black">Welcome Back to LegalMaster.AI</h1>
            <p className="white-sub text-dark">Your AI-powered Lawyer.</p>
          </div>
          <div className="row g-3 justify-content-center text-center">

            {categories.map((e)=>{
          return(
            <div className="col-xl-4 col-lg-4" key={e.id}>
              <Link 
              to={`/user/category/${Helpers.encryptNumber(e.id)}`} 
              className="title mb-3 text-white btn col-md-12" 
              style={{ background: "#0C1B44" }}>{e.name}</Link>
            </div>
            )
            })
            }

          </div>
          <div className="mt-4 text-center">
            <button
              className="btn btn-custom btn-rg btn-pill"
              onClick={() => initChat("")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-send-fill"
                viewBox="0 0 16 16"
              >
                <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
              </svg>
              <span>Start Discussing</span>
            </button>
          </div>
        </div>): <PageLoader/>}
      </div>
    </div>
  );
};

export default UserDashboard;