import React from 'react'

export default function CommingSoon() {
  return (
    <div className='d-flex justify-content-center align-items-center bg-white col-md-12'>
        <h1 className='fw-bold fs-1'>
        Comming Soon
        </h1>
    </div>
  )
}
