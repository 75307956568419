import React, { Component } from "react";
import { Link } from "react-router-dom";
export default class Donation extends Component {
  render() {
    return (
      <div>
        <section class="banner-style-six">
          <div class="pattern-layer">
            <div
              class="pattern-1"
              style={{
                backgroundImage: "url(assets/images/shape/shape-49.png)",
              }}
            ></div>
            <div
              class="pattern-2"
              style={{
                backgroundImage: "url(assets/images/shape/shape-50.png)",
              }}
            ></div>
          </div>
          <div class="auto-container">
            <div class="row align-items-center">
              <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                <div class="content-box ml_30">
                  <span
                    data-animation-text
                    class="sub-title-three overlay-anim-white-bg"
                    data-animation="overlay-animation"
                  >
                    Support Accessible Justice - Make a Difference Today
                  </span>
                  <h1 className="heading">Donate to Build Champions Non-Profit</h1>
                  <p>
                    Thank you for considering a donation to Build Champions
                    Non-Profit. Your support plays a pivotal role in our mission
                    to empower individuals and communities by making justice
                    accessible to all.
                  </p>
                  <p>
                    Empower Access to Justice: Your contribution helps us
                    provide cutting-edge AI legal services to public defenders,
                    legal aid clinics, law students, unrepresented individuals,
                    and small law firms, ensuring that justice is not a
                    privilege but a fundamental right for everyone.
                  </p>

                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                <div class="image-box ml_40 mr_50">
                  <div class="image-shape">
                    <div
                      class="shape-1"
                      style={{
                        backgroundImage:
                          "url(/assets/images/shape/shape-47.png)",
                      }}
                    ></div>
                    <div
                      class="shape-2 rotate-me"
                      style={{
                        backgroundImage:
                          "url(/assets/images/shape/shape-48.png)",
                      }}
                    ></div>
                  </div>
                  <figure class="image">
                    <img src="/app/legal-advice.png" alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="clients-section alternat-2 pt_40 pb_80 centred mt-5">
          <div class="auto-container">
            <div class="inner-container">
              <h3>"Justice is Not a Privilege but a Fundamental Right."</h3>
              <p>
                Our motto drives our commitment. Your donation helps us turn
                this motto into reality by ensuring that justice is accessible
                to all, regardless of their circumstances.
              </p>
            </div>
          </div>
        </section>

        <section class="about-style-four  pb_150 mt-3">
          <div class="auto-container">
            <div class="row align-items-center">
              <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                <div class="content_block_five">
                  <div class="content-box">
                    <div
                      class="shape"
                      style={{
                        backgroundImage:
                          "url(/assets/images/shape/shape-52.png)",
                      }}
                    ></div>

                    <figure class="image-box ">
                      <img src="/app/voice.png" alt="" />
                    </figure>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-6 col-md-12 col-sm-12 content-column"
                id="about"
              >
                <div class="content_block_six">
                  <div class="content-box ml_40">
                    <div data-animation-box class="sec-title mb_30"></div>
                    <div class="inner-box mb_40">
                      <div class="single-item mb_30">
                        <h3>How Your Contribution Helps</h3>
                        <p>
                          $50: Enhances our legal database resources for a
                          month.
                        </p>
                        <ul>
                          <li>
                            $100: Supports the drafting of 10 personalized legal
                            documents.
                          </li>
                          <li>
                            $250: Funds development of multilingual support for
                            a week.
                          </li>
                          <li>
                            $500: Sponsors an entire legal mock trial session
                            for students.
                          </li>
                          <li>
                            Any Amount: Makes a meaningful difference in our
                            mission to empower access to justice.
                          </li>
                          <li>
                            <p>
                              <strong>Our Commitment to Transparency:</strong>{" "}
                              We believe in complete transparency. Every dollar
                              you donate will be accounted for and used to
                              further our mission. We provide regular updates on
                              how your contributions are making an impact.
                            </p>
                            <p>
                              <strong>Tax Deductible Donations:</strong> We are
                              a 501(c)(3) Non-Profit Organization, which means
                              your donations are tax-deductible. When you
                              contribute to Build Champions Non-Profit, you not
                              only support our mission but also receive tax
                              benefits. Your generosity counts twice.
                            </p>
                            <div class="btn-box m-3">
                              <Link
                                to="/auth/Payments"
                                class="theme-btn btn-one"
                              >
                                Donate Now
                              </Link>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Additional List */}
          </div>
        </section>
      </div>
    );
  }
}
