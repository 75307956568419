import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ChatsContext } from "../../Contexts/ChatsContext";
import axios from "axios";
import Helpers from "../../Config/Helpers";
import PageLoader from '../../Components/Loader/PageLoader';
import { useNavigate } from "react-router-dom";
import { MdError } from "react-icons/md";
import ExpirationAlert from '../../Components/ExpirationAlert';

const CategoryQuestion = () => {
    const [showFalse, setShowFalse] = useState(false);
    const [showModel, setShowModel] = useState(false);
    const { setChats, showChat } = useContext(ChatsContext);
    const [queries, setQueries] = useState([]);
    const [category, setCategory] = useState({});
    const [isLoading,setIsLoading] = useState(false);
    const id = useParams();
    const navigate = useNavigate();

    const handleModelToggle = () => {
      setShowModel(!showModel);
    }

    const defineUser = () => {
        const user = Helpers.getItem("user", true);
        if (user) {
          Helpers.setItem("id", user.id);
        } else {
          axios.get(`https://api.ipify.org/?format=json`).then((response) => {
            Helpers.setItem("id", response.data.ip);
          });
        }
        getChats();
      };
    
      const getChats = () => {
        let userId = Helpers.getItem("id");
        axios.get(`${Helpers.apiUrl}chat/all/${userId}`, Helpers.authHeaders).then((response) => {
          setChats(response.data);
        });
      };

    const getQueries = () => {
        axios.get(`${Helpers.apiUrl}queries/single/${Helpers.decryptNumber(id.id)}`, Helpers.authHeaders).then(response=> {
            setQueries(response.data)
            if(response.data.length === 0){
                setShowFalse(true)
            }
        })
    }

    const getCategories = () => {
      axios.get(`${Helpers.apiUrl}categories/getOne/${Helpers.decryptNumber(id.id)}`, Helpers.authHeaders).then(response=>{
        setCategory(response.data);
        setIsLoading(true)
      }).catch(err=>{
        Helpers.toast(err.message)
      })
    }

    const initChat = (message = "") => {
        if (message) {
          Helpers.setItem("message", message);
        }
        let chatId = Helpers.generateChatId();
        navigate(`/user/chat/${chatId}`);
      };

    useEffect(()=>{
        defineUser();
        getQueries();
        getCategories();
      },[])
      
    return (
        <div
        className={`tyn-main ${showChat ? "main-shown" : ""}`}
        id="tynMain"
        style={{ overflow: "auto", backgroundColor: "white" }}
      >
        <div className="tyn-section">
          {(queries.length > 0  && isLoading ) ? (<div className="container-fluid">
            <ExpirationAlert />
            <div className="tyn-text-block text-center pb-4 pb-lg-5">
              <a className="tyn-logo" href="index.html">
                <img src="/app/logo-duo.png" alt="logo" />
              </a>
              <h1 className="mt-3 black">Welcome Back to LegalMaster.AI</h1>
              <p className="white-sub text-dark">Your AI-powered Lawyer.</p>
            </div>

            <div className="row g-3 justify-content-center text-center">
              {queries.map((e, index)=>{
                return(
                <div className="col-xl-4 col-lg-4" key={e.id}>
                    <div
                        key={index}
                        className={`col-sm-6 col-md-5 col-lg-12 ${index > 0 ? "d-none d-sm-block" : ""}`}
                    >
                        <div
                        className="card h-100 border-0 pointer card-custom"
                        style={{ background: "#0C1B44" }}
                        onClick={() => {
                            initChat(e.query);
                            navigate("/user/form"); 
                        }}
                        
                        >
                        <div className="card-body" style={{ background: "#0C1B44" }}>
                            <div className="tyn-text-block" style={{ background: "#0C1B44" }}>
                            <p>{e.query}</p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                )
                })
                }  
            </div>

            <div className="mt-4 text-center">
              <button
                className="btn btn-custom btn-rg btn-pill"
                onClick={() => initChat("")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-send-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
                </svg>
                <span>{category?.bContent || "Start Consulting"}</span>
              </button>
            </div>
          </div>)
          : 
          showFalse ? (
        <div className="d-flex justify-content-center align-items-center h-screen fs-1">
          <MdError className="mx-2" />
          <p>No Query Found</p>
        </div>
          ) 
          : 
          <PageLoader/> }
        </div>
      </div>
    )
}

export default CategoryQuestion;