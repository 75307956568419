const formatDate = (dateString) => {
    // Create a new Date object from the provided date string
    const date = new Date(dateString);
  
    // Define an array of month names
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
  
    // Get the day, month, year, hours, and minutes from the date object
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
  
    // Convert hours to AM/PM format and adjust for 12-hour format
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)
    minutes = minutes < 10 ? '0' + minutes : minutes; // Add leading zero for single-digit minutes
  
    // Construct the formatted date string
    const formattedDate = `Thu, ${month} ${day}th ${year}, ${hours}:${minutes} ${ampm}`;
  
    return formattedDate;
  };

  export default formatDate;