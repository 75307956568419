import { Notyf } from 'notyf';
import 'notyf/notyf.min.css'
import random from 'random-string-generator';

class Helpers{
    static localhost = '127.0.0.1:8000';
    static server = 'api.legalmasterai.com';
    static testEnv = 'test-backend.legalmasterai.com';
    static basePath = `//${this.server}`;
    static apiUrl = `${this.basePath}/api/`;
    static imageUrl = `${this.basePath}`; 

    static authUser = JSON.parse(localStorage.getItem('user')) ?? {};
    static serverImage = (name) => {
      return `${this.basePath}/uploads/${name}`;
    }

    static authHeaders = {
        headers: {
            "Content-Type": 'application/json',
            "Authorization" : `Bearer ${localStorage.getItem('token')}`
        }
    }
    
    static authFileHeaders = {
        headers: {
            "Content-Type": 'multipart/form-data',
            "Authorization" : `Bearer ${localStorage.getItem('token')}`
        }
    }

    static getItem = (data, isJson = false) => {
      if(isJson){
        return JSON.parse(localStorage.getItem(data));
      }else{
        return localStorage.getItem(data);
      }
    }

    static setItem = (key, data, isJson = false) => {
      if(isJson){
        localStorage.setItem(key, JSON.stringify(data));
      }else{
        localStorage.setItem(key, data);
      }
    }

    static toast = (type, message) => {
        const notyf = new Notyf();
        notyf.open({
            message: message,
            type:type,
            position:{x:'right', y:'top'},
            ripple:true,
            dismissible:true,
            duration:2000,
        });
    }

    static encryptNumber = (number) => {
        let key =992342443;
        return number ^ key; // Simple XOR operation
    }
    
    // Decryption function for integers
    static decryptNumber = (encryptedNumber) => {
        let key =992342443;
        return encryptedNumber ^ key; // Simple XOR operation
    }

            // Encryption function
        static encrypt = (text) => {
            let key = 'secret';
            let result = '';
            for (let i = 0; i < text.length; i++) {
                let char = text.charCodeAt(i);
                let keychar = key.charCodeAt(i % key.length);
                result += String.fromCharCode(char + keychar);
            }
            return result;
        }

        // Decryption function
        static decrypt = (text) => {
            let key = 'secret';
            let result = '';
            for (let i = 0; i < text.length; i++) {
                let char = text.charCodeAt(i);
                let keychar = key.charCodeAt(i % key.length);
                result += String.fromCharCode(char - keychar);
            }
            return result;
        }

    static toggleCSS() {
        const path = window.location.pathname;
    
        // Assuming you have class names 'main-theme' and 'dashboard-theme' for your CSS links
        const mainCSS = document.getElementsByClassName('main-theme');
        const dashboardCSS = document.getElementsByClassName('dashboard-theme');
    
        if (path.includes('/auth') || path.includes('/user') || path.includes('/admin')) {
            // Disable all main theme stylesheets
            for (let i = 0; i < mainCSS.length; i++) {
                mainCSS[i].setAttribute('disabled', 'true');
            }
            // Enable all dashboard theme stylesheets
            for (let i = 0; i < dashboardCSS.length; i++) {
                dashboardCSS[i].removeAttribute('disabled');
            }
        } else {
            // Enable all main theme stylesheets
            for (let i = 0; i < mainCSS.length; i++) {
                mainCSS[i].removeAttribute('disabled');
            }
            // Disable all dashboard theme stylesheets
            for (let i = 0; i < dashboardCSS.length; i++) {
                dashboardCSS[i].setAttribute('disabled', 'true');
            }
        }
    }

    static loadScript(scriptName, dashboard = false) {
        return new Promise((resolve, reject) => {
            const scriptPath = `/${ dashboard ? "dashboard" : "assets" }/js/${scriptName}`;
            const script = document.createElement('script');
            script.src = scriptPath;
            script.async = true;

            script.onload = () => resolve(script); // Resolve the promise once the script is loaded
            script.onerror = () => reject(new Error(`Script load error: ${scriptPath}`));

            document.body.appendChild(script);
        });
    }

    static paginate = data => {
        let pageSize = 10;
        let paginated = [];
        let startIndex = 0;
        let totalPages = Math.ceil(data.length / pageSize);
        for(let i = 0; i < totalPages; i++){
            let lastIndex = pageSize + startIndex;
            let pageData = data.slice(startIndex, lastIndex);
            paginated.push(pageData);
            startIndex += pageSize;
        }
        return paginated;
    }

    static generateChatId = () => {
        // Function to generate a single random uppercase character
        function getRandomChar() {
            // Generate a random number between 65 and 90 (ASCII codes for uppercase letters)
            var randomNumber = Math.floor(Math.random() * (90 - 65 + 1)) + 65;
            // Convert the random number to its corresponding character
            var randomChar = String.fromCharCode(randomNumber);
            return randomChar;
        }
    
        // Function to generate a section of the string
        function generateSection() {
            let section = '';
            for (let i = 0; i < 5; i++) {
                section += getRandomChar();
            }
            return section;
        }
    
        // Generate the full string with sections and dashes
        return generateSection() + '-' + generateSection() + '-' + generateSection() + '-' + generateSection();
    }
    
}

export default Helpers;