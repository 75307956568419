import React, { useState, useRef } from 'react';
import { FaRegFilePdf } from 'react-icons/fa';
import styles from '../../CSS/Mockup-Trial.module.css';

const DragAndDropUploader = ({ person ,onFileSelected }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleDragOver = (event) => {
    event.preventDefault();
    if (event.dataTransfer.types[0] === 'Files') {
      setIsDragging(true);
    }
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const droppedFile = event.dataTransfer.files[0];
    handleFileSelection(droppedFile);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    handleFileSelection(file);
  };

  const handleFileSelection = (file) => {
    if (file.type === 'application/pdf') {
      setSelectedFile(file);
      onFileSelected(file);
    } else {
      alert('Only PDF files are allowed.');
    }
  };

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div
      className={`plaintiff ${styles.min_height} text-center w-100 w-100 d-flex flex-column align-items-center justify-content-center`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={handleDivClick}
      style={{
        backgroundColor: isDragging ? '#ccc' : 'transparent',
        cursor: isDragging ? 'copy' : 'pointer',
      }}
    >
      <FaRegFilePdf fill="#8B8589" size={'4rem'} />
      <h2 className="text-secondary">Upload {person}'s PDF</h2>
      <p>
        {selectedFile ? (
          <span>Selected: {selectedFile.name}</span>
        ) : (
          <span>Please Drag and Drop a PDF file or click to select</span>
        )}
      </p>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileInputChange}
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default DragAndDropUploader;
