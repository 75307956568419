import axios from "axios";
import { useEffect, useState } from "react";
import Helpers from "../../../Config/Helpers";
import PageLoader from '../../../Components/Loader/PageLoader'

const Users = () => {
    const [users, setUsers] = useState([]);
    const [pageLoading, setPageLoading]  = useState(true);

    const getUser = () => {
        axios.get(`${Helpers.apiUrl}user/all`,Helpers.authHeaders).then((res)=>{
            setUsers(res.data.users)
            Helpers.toast('success', res.data.message);
            setPageLoading(false);
        }).catch((error)=>{
            console.error(error);
            setPageLoading(false);
            Helpers.toast('error',error);
        })
    }
    useEffect(() => {
        getUser();
    }, []);

    return (
        <div class="nk-content container-xl bg-white">
            <div class="container-xl">
            { (pageLoading) ? <PageLoader/> : (<div class="nk-content-inner">
                    <div class="nk-content-body">
                        <div class="nk-block-head nk-page-head">
                            <div class="nk-block-head-between">
                                <div class="nk-block-head-content">
                                    <h2 class="display-6">User</h2>
                                    <p>Manage all the Users</p>
                                </div>
                            </div>
                        </div>
                        <div class="nk-block">
                            {/* <SearchHeader title={"All Categories"} orgData={orgData} setData={setCategories} columns={['name']} /> */}
                            <div class="card shadown-none">
                                <div class="card-body">
                                    <div class="row g-3 gx-gs">
                                        <div className="col-md-12">
                                        <div className="table-container" style={{maxHeight: "70vh",overflowY: "auto"}}>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Sr. #</th>
                                                        <th>Customer</th>
                                                        <th>Email</th>
                                                        <th>Plan</th>
                                                        <th>Pricing</th>
                                                        <th>Subscribe Date</th>
                                                        <th>Expire Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {users.length === 0 && <tr>
                                                        <td colSpan={4}>No records found...</td>
                                                    </tr>}
                                                    {users?.map((user, index) => {
                                                        return (
                                                            <tr key={index + 1} className="m-2 p-1">
                                                                <td>{ index + 1 }</td>
                                                                <td>{user.name}</td>
                                                                <td>{user.email}</td>
                                                                <td>{user.plan_name || "-"}</td>
                                                                <td>{user.price || "-"}</td>
                                                                <td>{user.subscribed_date}</td>
                                                                <td>{user.expiration_date}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
            </div>
        </div>
    )
}

export default Users;