import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useEffect, useId, useRef, useState } from "react";
import { MdKeyboardVoice } from "react-icons/md";
import Helpers from '../Config/Helpers'

const AskAnything = ({ getReply, message, setMessage, isLoading = false, isVoice = true }) => {
    const textareaRef = useRef(null);
    let browserSupport = true;
    const [isListening, setIsListening] = useState(false);
    const [user, setUser] = useState(Helpers.getItem('user'));
    const [expired, setExpired] = useState(false);

    useEffect(() => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (SpeechRecognition) {
        const recognition = new SpeechRecognition();
        recognition.continuous = true;
        recognition.interimResults = true;

        recognition.onstart = () => console.log('Speech recognition activated. Please speak.');

        recognition.onresult = (event) => {
            const transcript = Array.from(event.results)
            .map(result => result[0])
            .map(result => result.transcript)
            .join('');
            setMessage(transcript);
        };

        recognition.onerror = (event) => console.error('Error during recognition:', event.error);

        if (isListening) {
            recognition.start();
        } else {
            recognition.stop();
        }

        return () => recognition.stop();
        }
    }, [isListening]);

    const handleListen = () => setIsListening(!isListening);

    const resizeTextarea = () => {
        if (textareaRef.current && message) {
            if(textareaRef.current.scrollHeight > 40){
                textareaRef.current.style.height = `${textareaRef.current.scrollHeight / 3}px`;
            }
        }else{
            textareaRef.current.style.height = 'auto';
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault();
          getReply();
        }
    };


    const handleInputChange = e => {
        const msg = e.target.value;
        setMessage(msg)
        resizeTextarea();
    }

    const submitForm = e => {
        e.preventDefault();
        getReply();
        handleListen();
    }

    useEffect(() => {
        resizeTextarea();
    }, [message]);
    
    useEffect(()=>{
        setUser(JSON.parse(user))
        const userExpirationDate = JSON.parse(user).expiration_date;

        // Get the current date
        const currentDate = new Date();

        // Convert user's expiration date to a JavaScript Date object
        const expirationDate = new Date(userExpirationDate);
        const isExpired = currentDate > expirationDate;

        const exhaustedWords = user.no_words > user.used_words;
        // Check if the current date is after the expiration date
        if (isExpired || exhaustedWords) {
            setExpired(true);
        } else {
            // User has not expired
            setExpired(false)
        }
    }, []);
    return (
        <div className="tyn-chat-form border-0 px-4">
            <div className="container px-0">
                <div className="ps-3 pe-4 py-3 bg-custom mb-5 rounded-3">
                    <form onSubmit={getReply}>
                        <div className="tyn-chat-form-enter">
                            
                            <textarea ref={textareaRef} 
                            className="tyn-chat-form-input" 
                            rows={1} value={message} 
                            onKeyDown={handleKeyPress} 
                            onChange={handleInputChange} 
                            placeholder={`${expired ? "You Plan has been Expired" : "Send a message"}`} 
                            style={{ border: 'none',resize: 'none'  }}
                            disabled={expired}
                            ></textarea>
                            
                            <ul className="tyn-list-inline me-n2 my-1">
                                <li>
                                    <button disabled={!message} onClick={submitForm} className="btn btn-custom">        
                                        {/* <FeatherIcon icon={'send'} /> Send */}
                                        {isLoading ? <>
                                            <i className="fa-duotone fa-spinner-third fa-spin"></i> Writing
                                        </> : <>
                                            <i className="fa-light fa-paper-plane"></i> Send
                                        </>}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </div>
            {/* Voice Button */}
            {(isVoice && browserSupport) && (<div className="ps-3 pe-4 py-3 mb-5 rounded-3">
                <button
                style={{borderRadius: "50%", padding: '15px'}}
                onClick={handleListen}
                className={`${isListening ? 'btn btn-lg btn-danger recording' :'btn btn-prim btn-lg'}`}
                disabled={expired}
                >
                    <MdKeyboardVoice 
                    style={isListening ? {color: 'white'} : {}}/>
                </button>
            </div>)}
        </div>
    );
}

export default AskAnything;