import React from "react";
import { ThreeDots } from 'react-loader-spinner'
import styles from '../../CSS/Mockup-Trial.module.css'

const MapMessages = ({ icon, text, messages, chatbot, isLoading }) => {
    const renderBoldText = (msg) => {
        const boldRegex = /\*\*(.*?)\*\*/g;
        const parts = msg.split(boldRegex);
        return parts.map((part, index) => {
            if (part.match(boldRegex)) {
                return (
                    <span key={index}>
                        <strong>{part.replace(/\*\*/g, '')}</strong>
                        <br />
                    </span>
                );
            } else {
                return part.split('\n').map((line, index) => (
                    <span key={index}>
                        {line}
                        <br />
                    </span>
                ));
            }
        });
    };

    return (
        <div>
            {messages?.map((msg, index) => {
                const contentWithBoldText = chatbot ? renderBoldText(msg) : msg;

                return (
                    <>
                        <div key={index} className={`card border-0 my-3 mx-1 ${styles.message_card}`}>
                            <div className="d-flex flex-row align-items-center mt-2 mx-2">
                                {icon} <strong className="mx-2" style={{ color: "black" }}>{text}</strong>
                            </div>
                            <div className="card-body p-2">
                                <blockquote className="blockquote mb-0">
                                    <p className={`${styles.font_size_md}`}>{contentWithBoldText}</p>
                                </blockquote>
                            </div>
                        </div>

                        {(isLoading && chatbot && (index === messages.length - 1 || messages.length === 0)) && 
                        (<div key={index} className={`card border-0 my-3 mx-1 ${styles.message_card}`}>
                            <div className="d-flex flex-row align-items-center mt-2 mx-2">
                                {icon} <strong className="mx-2" style={{ color: "black" }}>{text}</strong>
                            </div>
                            <div className="card-body p-2">
                                <blockquote className="blockquote mb-0">
                                    <ThreeDots
                                        className={`${styles.font_size_md}`}
                                        visible={true}
                                        height="30"
                                        width="80"
                                        color="#d7d6d6"
                                        radius="9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                    />
                                </blockquote>
                            </div>
                        </div>)}
                    </>
                );
            })}
                    {(chatbot && messages.length === 0) && 
                        (<div className={`card border-0 my-3 mx-1 ${styles.message_card}`}>
                            <div className="d-flex flex-row align-items-center mt-2 mx-2">
                                {icon} <strong className="mx-2" style={{ color: "black" }}>{text}</strong>
                            </div>
                            <div className="card-body p-2">
                                <blockquote className="blockquote mb-0">
                                    <ThreeDots
                                        className={`${styles.font_size_md}`}
                                        visible={true}
                                        height="30"
                                        width="80"
                                        color="#d7d6d6"
                                        radius="9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                    />
                                </blockquote>
                            </div>
                        </div>)}
        </div>
    );
};

export default MapMessages