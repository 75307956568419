import React, {useState, useEffect} from 'react'
import Helpers from '../../Config/Helpers';
import PageLoader from '../../Components/Loader/PageLoader'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { IoPersonSharp } from "react-icons/io5";

export default function AdminDashboard() {
  const [categories, setCategories] = useState([]);
  const [queries, setQueries] = useState([]);
  const [guestLimit, setGuestLimit] = useState([]);
  const [user, setUsers] = useState([]);

    const getCategories = () => {
      axios.get(`${Helpers.apiUrl}categories/all`, Helpers.authHeaders).then(response => {
        setCategories(response.data);
    });
    }

    const getQueries = () => {
      axios.get(`${Helpers.apiUrl}queries/all`, Helpers.authHeaders).then(response => {
        setQueries(response.data);
    });
    }
 
    const getSettings = () => {
      axios.get(`${Helpers.apiUrl}free-plan/all`, Helpers.authHeaders).then(response => {
          setGuestLimit(response.data);
      });
    }

    const getUsers = () => {
      axios.get(`${Helpers.apiUrl}user/all`, Helpers.authHeaders).then(response=>{
        setUsers(response.data.users)
      })
    }

    useEffect(()=>{
      getCategories();
      getQueries();
      getSettings();
      getUsers();
    }, [])
  return (
    <div class="nk-content container-xl bg-white col-md-12">
    <div class="container-xl">
        { (!(categories.length > 0 && queries.length > 0 && guestLimit.length > 0 )) ? <PageLoader/> : (<div class="nk-content-inner">
            <div class="nk-content-body">
                <div class="nk-block-head nk-page-head">
                    <div class="nk-block-head-between">
                        <div class="nk-block-head-content">
                            <h2 class="display-6">Dashboard</h2>
                            <p></p>
                        </div>
                        <div className="d-flex justify-content-end m-2">

                        <div class="nk-block col-md-12">
                          <div class="row g-gs">
                              <div class="col-sm-6 col-xxl-3">
                                <div class="card card-full bg-purple bg-opacity-10 border-0">
                                    <div class="card-body">
                                      <div class="d-flex align-items-center justify-content-between mb-1">
                                          <div class="fs-6 mb-0">Word Limit</div>
                                          <a class="link link-purple"
                                          href="/admin/settings"
                                          >See All</a>
                                      </div>
                                      <h5 class="fs-1">{guestLimit.map((limit)=> {
                                            if(limit.activation_status === "1"){
                                              return(
                                                <>{limit.no_of_words}</>
                                              )
                                            }
                                          })} <small class="fs-3">Words</small></h5>
                                    </div>
                                </div>
                              </div>

                              <div class="col-sm-6 col-xxl-3">
                                <div class="card card-full bg-blue bg-opacity-10 border-0">
                                    <div class="card-body">
                                      <div class="d-flex align-items-center justify-content-between mb-1">
                                          <div class="fs-6 mb-0">Queries</div>
                                          <a class="link link-blue" 
                                          href="/admin/queries"
                                          >See All</a>
                                      </div>
                                      <h5 class="fs-1">{queries.length} <small class="fs-3">Queries</small></h5>
                                    </div>
                                </div>
                              </div>

                              <div class="col-sm-6 col-xxl-3">
                                <div class="card card-full bg-indigo bg-opacity-10 border-0">
                                    <div class="card-body">
                                      <div class="d-flex align-items-center justify-content-between mb-1">
                                          <div class="fs-6 mb-0">Categories</div>
                                          <a class="link link-indigo" 
                                          href="/admin/categories"
                                          >See All</a>
                                      </div>
                                      <h5 class="fs-1">{categories.length} <small class="fs-3">Categories</small></h5>
                                    </div>
                                </div>
                              </div>

                              <div class="col-sm-6 col-xxl-3">
                                <div class="card card-full bg-cyan bg-opacity-10 border-0">
                                    <div class="card-body">
                                      <div class="d-flex align-items-center justify-content-between mb-1">
                                          <div class="fs-6 mb-0">Users</div>
                                          <a class="link link-cyan" 
                                          href="/admin/users"
                                          >See All</a>
                                      </div>
                                      <h5 class="fs-1">{user.length} <small class="fs-3">Users</small></h5>
                                    </div>
                                </div>
                              </div>

                          </div>
                        </div>
                      </div>


                        <div class="nk-block-head mt-5">
                            <div class="nk-block-head-between d-flex flex-row justify-content-between align-items-center">
                                <div class="nk-block-head-content">
                                  <h2 class="display-6">Recent Users</h2>
                                </div>
                                <div class="nk-block-head-content"><a class="link" href="/admin/users">Explore All</a></div>
                            </div>
                          </div>
                          <div class="nk-block">
                            <div class="row g-gs">
                                {user.map((user, index) => {
                                  if(index < 4){
                                        return (
                                          <div class="col-sm-6 col-xxl-3 py-2">
                                              <UserCard 
                                              name={user.name}
                                              description={user.email}
                                              avatar={
                                              <IoPersonSharp
                                              className='p-1'
                                              style={styles.avatar}
                                              ></IoPersonSharp>
                                              }></UserCard>
                                            </div>
                                         );
                                    }
                                })} 
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>)}
    </div>
    </div>
  )
}







const UserCard = ({ avatar, name, description }) => {
  return (
    <div style={styles.userCard}>
      {avatar}
      <div className='mx-2'>
      <h3 style={styles.nameStyle}>{name}</h3>
      <p style={styles.descriptionStyle} data-toggle="tooltip" title={description}> 
        {description.length > 23 ? description.substring(0, 15) + '...' : description}
      </p>
      </div>
    </div>
  );
};

const styles = {
  userCard: {
    display: "flex",
    flex: "row",
    padding: '30px 15px',
    boxShadow: '0px 5px 15px rgba(0,0,0,.2)',
    borderRadius: '10px',
    minWidth: '260px',
  },
  nameStyle: {
    margin: '0',
    fontSize: '1.5rem',
  },
  descriptionStyle: {
    margin: '10px 0',
    fontSize: '1rem',
    color: '#666',
  },
  avatar: {
    fontSize: "2rem",
    color: "white",
    borderRadius: '50%',
    background: "lightblue"
  },
};
