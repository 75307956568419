import React from 'react'
import { Modal, Button } from 'react-bootstrap';

export default function CaseDetails({caseDetails, setCaseDetails, handleShow, handleClose, show}) {
  return (
        <Modal show={show}>
        <Modal.Header >
        <Modal.Title>Case Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div class="mb-3">
            <label for="basic-url" class="form-label">Case No:</label>
            <div class="input-group">
                <span class="input-group-text" id="basic-addon3">#</span>
                <input 
                type="text" 
                class="form-control" 
                id="basic-url"
                value={caseDetails.caseNo} 
                onChange={(e)=>setCaseDetails({...caseDetails, caseNo: e.target.value})}
                autoComplete="off" 
                aria-describedby="basic-addon3 basic-addon4"/>
            </div>
        </div>
        <div class="mb-3">
            <label for="basic-url" class="form-label">Case Title:</label>
            <div class="input-group mb-3">
            <input 
            type="text" 
            class="form-control" 
            placeholder="Case Title"
            value={caseDetails.title} 
            onChange={(e)=>setCaseDetails({...caseDetails, title: e.target.value})} 
            aria-label="Username" 
            autoComplete="off" 
            aria-describedby="basic-addon1"/>
            </div>
        </div>
        </Modal.Body>
        <div className='d-flex flex-row justify-content-end mx-2'>
        <button onClick={handleClose} className='btn btn-prim border-0 m-2'>
            Save Changes
        </button>
        </div>
    </Modal>
  )
}
