import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Helpers from "../../Config/Helpers";
import axios from "axios";
import { useParams } from "react-router-dom";


const Register = () => {
  const team_id = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const defaultUser = {
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    // team_id: team_id ? Helpers.decryptNumber(team_id.id) : 0
    team_id:  (team_id.id !== undefined) ? Helpers.decryptNumber(team_id.id) : 0
  };

  const [user, setUser] = useState(defaultUser);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleRegister = () => {
    setIsLoading(true);
    setErrors({});
    axios
      .post(`${Helpers.apiUrl}auth/register`, user)
      .then((response) => {
        Helpers.toast("success", response.data.message);
        Helpers.setItem("user", response.data.user, true);
        Helpers.setItem("token", response.data.token);
        Helpers.setItem("id", response.data.user.id);
        setIsLoading(false);
        navigate("/auth/login");
      })
      .catch((error) => {
        if (error.response) {
          setErrors(error.response.data || {});
          Helpers.toast(
            "error", 
            error.response.data.email || 
            error.response.data.password || 
            error.response.data.name
            );
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    Helpers.toggleCSS();
  }, [location.pathname]);

  useEffect(()=>{
    console.log((team_id.id !== undefined) ? Helpers.decryptNumber(team_id.id) : 0 )
  }, [team_id])
  return (
    <div class="tyn-root " style={{ background: "#F8F8F8" }}>
      <div class="tyn-content tyn-auth tyn-auth-centered">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-8">
              <div class="my-3 text-center">
                <img src="/app/Legaldrafting.png" className="w200" />
              </div>
              <div
                class="card border-0 card-custom"
                style={{ background: "white" }}
              >
                <div class="p-4">
                  <h3 className="white">Create Free Account</h3>
                  <div class="row g-3">
                    <div class="col-6">
                      <div class="form-group">
                        <label class="form-label white-sub">Your Name</label>
                        <div class="form-control-wrap">
                          <input
                            type="text"
                            value={user.name}
                            onChange={(e) =>
                              setUser({ ...user, name: e.target.value })
                            }
                            class="form-control "
                            placeholder="Your Name"
                          />
                          <small className="text-danger">
                            {errors.name ? errors.name[0] : ""}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label class="form-label white-sub">
                          Email Address
                        </label>
                        <div class="form-control-wrap">
                          <input
                            type="text"
                            value={user.email}
                            onChange={(e) =>
                              setUser({ ...user, email: e.target.value })
                            }
                            class="form-control "
                            placeholder="youremail@example.com"
                          />
                          <small className="text-danger">
                            {errors.email ? errors.email[0] : ""}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label class="form-label d-flex white-sub">
                          Password
                        </label>
                        <div class="form-control-wrap">
                          <input
                            type="password"
                            value={user.password}
                            onChange={(e) =>
                              setUser({ ...user, password: e.target.value })
                            }
                            class="form-control "
                            placeholder="Password"
                          />
                          <small className="text-danger">
                            {errors.password ? errors.password[0] : ""}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label class="form-label d-flex white-sub">
                          Confirm Password
                        </label>
                        <div class="form-control-wrap">
                          <input
                            type="password"
                            value={user.password_confirmation}
                            onChange={(e) =>
                              setUser({
                                ...user,
                                password_confirmation: e.target.value,
                              })
                            }
                            class="form-control "
                            placeholder="Confirm Password"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <button
                        onClick={handleRegister}
                        disabled={isLoading}
                        class="btn w-100"
                        style={{ background: "#0C1B44" , color : 'white' }}
                      >
                        {isLoading
                          ? "Creating Your Account"
                          : "Create Free Account"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center mt-4">
                <p class="small white-sub  ">
                  Already have an account?{" "}
                  <Link className="color-primary" to="/auth/login">
                    Login to Account
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
