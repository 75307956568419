import { useState, useContext } from "react";
import NavbarContext from "./NavbarContext";

const NavbarProvider = ({children}) => {
  const [sideBarIsOpen, setSideBarIsOpen] = useState(false)
  const [hasSideBar, setHasSideBar] = useState(true);
    const state = {
        sideBarIsOpen: sideBarIsOpen,
        hasSideBar: hasSideBar
    }

    const toggleStates = () => {
      if(sideBarIsOpen){
        setHasSideBar(true);
        state.hasSideBar = true;
      }else {
      setHasSideBar(false);
      state.hasSideBar = false;
      }
      setSideBarIsOpen(!sideBarIsOpen)
      state.sideBarIsOpen = (!sideBarIsOpen);
    }

    return (
    <NavbarContext.Provider value={{state:state, toggle: toggleStates}}>
        {children}
    </NavbarContext.Provider>
    )
}

export default NavbarProvider;