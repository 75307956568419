import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { FaPlusCircle } from 'react-icons/fa';
import { MdOutlineRemoveRedEye, MdDeleteOutline, MdEdit } from "react-icons/md";
import { IoCheckmark, IoSaveSharp } from "react-icons/io5";
import { IoMdCodeWorking } from "react-icons/io";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { TbCategoryFilled } from "react-icons/tb";
import axios from 'axios';
import Helpers from '../../../Config/Helpers';
import PageLoader from '../../../Components/Loader/PageLoader'
import { useNavigate } from 'react-router-dom';
import { RxCross2 } from "react-icons/rx";

let modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false,
    }
  }

  let formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ]

const defaultBlog = {
    image: null,
    title: "",
    category: "",
    content: ""
}

const defaultMetaData = {
    meta_title: "",
    meta_description: "",
    meta_tags: ""
}

const defaultCategory = {
    name: ''
};

const options = [
    {
        value: "categoryy",
        label: "category"
    }
]

const BlogEditor = () => {
    const navigate = useNavigate();
    const [blog, setBlog] = useState(defaultBlog);
    const [metadata, setMetadata] = useState(defaultMetaData);
    const [blogs, setBlogs] = useState([]);
    const [content, setContent]  = useState('');
    const [metaModel, setMetaModel] = useState(false); 
    const [categories, setCategories] = useState(options);
    const [errors, setErrors] = useState({})
    const [isMetaDataExpand, setIsMetaDataExpand] = useState(false);
    const [categoryBlog, setCategoryBlog] = useState(null);
    const [category, setCategory] = useState(defaultCategory);
    const [categoryModel, setCategoryModel] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [editableCategories, setEditableCategories] = useState([]);
    const [deleteCatetoryConfirm, setDeleteCategoryConfirm] = useState(0);

    const handleCategoryModel = () =>{
        setCategoryModel(!categoryModel);
    }

    const handleMetaModel = () =>{
        setMetaModel(!metaModel);
    }

    const handleCategoryChange = (categoryBlog) => {
      setCategoryBlog(categoryBlog);
      setBlog({...blog, category: categoryBlog.value})
    };

    
      const handleSaveBlog = async () => {
        if(metadata.meta_tags === '' || metadata.meta_description === '' || metadata.meta_title === ''){
            Helpers.toast('error', 'Metadata is Necessary');
            setIsMetaDataExpand(true);
            return;
        }
        setIsLoading(true)
        const formData = new FormData();
        formData.append('id', (blog.id !== undefined || null || 0) ? blog.id : 0);
        formData.append('title', blog.title);
        formData.append('image', blog.image);
        formData.append('category', blog.category);
        formData.append('content', content);
        formData.append('meta_id', (metadata.id !== undefined || null || 0) ? metadata.id : 0 );
        formData.append('meta_title', metadata.meta_title);
        formData.append('meta_description', metadata.meta_description);
        formData.append('meta_tags', metadata.meta_tags);
        try {
          const response = await axios.post(`${Helpers.apiUrl}blog/save`, formData, Helpers.authFileHeaders);
          setBlogs(response.data.blogs);
          Helpers.toast('success', response.data.message);
          setIsLoading(false)
          handleBlogModelCancel();
          setBlog(defaultBlog);
          setMetadata(defaultMetaData);
        } catch (error) {
          // Handle errors (e.g., display error message)
          setIsLoading(false)
          setErrors(error.response.data.errors);
          console.error('Error saving blog:', error);
          Helpers.toast('error', error.response.data.message);
        }
      };
      

      const handleEditBlog = (blog) => {
        setBlog(blog);
        setContent(blog.content);
        setCategoryBlog({value: blog.category, label: blog.category});
        console.log(blog.metadata);
        setMetadata(blog.metadata);
        setMetaModel(true);
      }

      const handleDelete = (id) => {
        setIsLoading(true);
        axios.get(`${Helpers.apiUrl}blog/delete/${id}`, Helpers.authHeaders).then((response)=>{
            setIsLoading(false);
            Helpers.toast('success', response.data.message);
            setBlogs(response.data.blogs);
            setBlog(defaultBlog);
            setMetadata(defaultMetaData);
        }).catch((error)=>{
            setIsLoading(false);
            console.log(error);
            Helpers.toast(error.response.data.message);
            setBlog(defaultBlog);
            setMetadata(defaultMetaData);
        })
      }

      const getBlogs = () => {
        setIsLoading(true);
        axios.get(`${Helpers.apiUrl}blog/getAll`, Helpers.authHeaders).then((response) => {
            setBlogs(response.data.blogs);
            setIsLoading(false);
        }).catch((error)=> {
            console.log(error.response.data.message);
            Helpers.toast('error', error.response.data.message);
            setIsLoading(false);
        })
      }

    const handleSaveCatogery = () => {
        setIsLoading(true);
        axios.post(`${Helpers.apiUrl}category/save`, category, Helpers.authHeaders).then((response)=>{
            Helpers.toast('success', response.data.message);
            getCategories();
            setBlog(defaultBlog);
            setMetadata(defaultMetaData);
            setIsLoading(false);
        }).catch((error)=>{
            setIsLoading(false);
            setErrors(error.response.data);
            Helpers.toast("error", error.response.data.message);
            console.log(error.response.data.message);
            setBlog(defaultBlog);
            setMetadata(defaultMetaData);
        })
    }
    
    const getCategories = () => {
        setIsLoading(true);
        axios.get(`${Helpers.apiUrl}category/get`, Helpers.authHeaders).then((response)=>{
            const categories = response.data.categories.map(category => ({
                value: category.category,
                label: category.category,
              }));
            setEditableCategories(response.data.categories);
            setCategories(categories);
            setIsLoading(false);
        }).catch((error)=>{
            setIsLoading(false);
            setErrors(error);
            Helpers.toast("error", error.response.data.message);
            console.log(error.response.data.message);
        })
    }

    const handleBlogModelCancel = () => {
        setContent(null);
        handleCategoryChange('');
        setBlog(defaultBlog);
        setMetadata(defaultMetaData);
        setMetaModel(!metaModel);
    }

    const handleCloseMetaModel = () => {
        setIsMetaDataExpand(!isMetaDataExpand)
    }
    
    const handleBlogClick = (blogId) => {
        navigate(`/Screen/blog/${blogId}`);
      };

      const handleEditCategory = (category) => {
        console.log(category);
        setCategory(category)
      }

      const handleDeleteCategory = (id) => {
        setIsLoading(true);
        axios.get(`${Helpers.apiUrl}category/delete/${id}`, Helpers.authHeaders)
        .then((response) => {
        const categories = response.data.categories.map(category => ({
            value: category.category,
            label: category.category,
            }));
        setEditableCategories(response.data.categories);
        setCategories(categories);
        setBlogs(response.data.blogs);
        setBlog(defaultBlog);
        setMetadata(defaultMetaData);
        Helpers.toast('success', 'Deleted Category Successfully');
        setCategories(response.data.categories);
        setIsLoading(false);  
        })
        .catch((error) => {
            setIsLoading(false);
            Helpers.toast('error', error.response.data.message);
            console.log(error)
            setErrors(error);
            setBlog(defaultBlog);
            setMetadata(defaultMetaData);
        })
      }

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setBlog({ ...blog, image: file });
      };

      useEffect(()=>{
        getCategories();
        getBlogs();
      }, [])

    return (
        <>
            <div class="nk-content container-xl bg-white"
            style={{
                height: '100vh',
                overflow: 'scroll'
            }}>
                <div class="container-xl">
                    {!isLoading ? (<div class="nk-content-inner">



                        {(!metaModel && !categoryModel) && (<div class="nk-content-body">
                            <div class="nk-block-head-content d-flex justify-content-between align-items-center">
                                <div className="m-2">
                                    <h2 class="display-6">Blogs</h2>
                                    <p>Manage Blogs</p>
                                </div>
                                <div>
                                <button disabled={isLoading}
                                className='btn btn-sm btn-prim my-5 mx-1' 
                                style={{ maxHeight: '30px !important' }}
                                onClick={handleCategoryModel}><TbCategoryFilled /> Add Categories</button>
                                <button disabled={isLoading}
                                className='btn btn-sm btn-prim my-5 mx-1'
                                style={{ maxHeight: '30px !important' }}
                                onClick={handleMetaModel}><FaPlusCircle /> Add Blog</button>
                                </div>
                            </div>
                            <div class="nk-block">
                                {blogs.length > 0 ? blogs.map((blog)=> {
                                    return(
                                        <>
                                        <div class="card shadow border-0 my-3">
                                            <div class="card-body">
                                                <div className='d-flex flex-row justify-content-between align-items-center'>
                                                    <div className='d-flex flex-row justify-content-center align-items-center'>
                                                        <img
                                                            className='m-2 p-2'
                                                            src={`${Helpers.imageUrl}/images/${blog.image}`}
                                                            style={{ width: "80px", borderRadius: "20px" }}></img>
                                                        <div>
                                                            <h4 className='display-6'>{blog.title}</h4>
                                                            <p style={{maxWidth: "30vw"}}>{blog.content.length > 100 ? `${blog.content.slice(0,100)}...` : blog.content}</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button disabled={isLoading}
                                                        className='btn btn-md btn-outline-primary m-1' 
                                                        onClick={()=>handleBlogClick(blog.id)}><MdOutlineRemoveRedEye />View</button>
                                                        <button  disabled={isLoading}
                                                        className='btn btn-md btn-outline-success m-1' 
                                                        onClick={()=>handleEditBlog(blog)}><MdEdit /> Edit</button>
                                                        <button disabled={isLoading}
                                                        className='btn btn-md btn-outline-danger m-1' 
                                                        onClick={()=>handleDelete(blog.id)}><MdDeleteOutline /> Delete</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                    )
                                }) : <p className='m-3'>No Blogs Available</p>}
                            </div>
                        </div>)}


                        {(metaModel && !categoryModel) && (<div class="nk-content-body">
                            <div class="nk-block-head-content d-flex justify-content-between align-items-center">
                                <div className="m-2">
                                    <h2 class="display-6">Write</h2>
                                </div>
                                            <button 
                                            className='btn btn-sm btn-prim' 
                                            style={{ maxHeight: '30px !important' }} 
                                            onClick={()=>setIsMetaDataExpand(!isMetaDataExpand)}><IoMdCodeWorking /> Metadata</button>
                                </div>
                            <div class="nk-block container">
                                <div className='row justify-content-between'>
                                    <div class={`card shadow border-0 ${isMetaDataExpand && 'col-7'}`}
                                    style={{height: "85vh"}}>
                                        <div class="card-body">
                                        <div class="form-group">
                                                    <label class="form-label">Image:</label>
                                                    <div class="form-control-wrap">
                                                    <input
                                                    type="file"
                                                    onChange={(e)=> {
                                                        setBlog({...blog, image: e.target.files[0]})
                                                    }}
                                                    className="form-control"
                                                    placeholder="Upload Image"
                                                    />
                                                        <small className="text-danger">{ errors.image ? errors.image[0] : '' }</small>
                                                    </div>
                                            </div>
                                            <div class="form-group">
                                                    <label class="form-label">Title:</label>
                                                    <div class="form-control-wrap">
                                                        <input type="text" value={blog.title} onChange={e =>{ 
                                                            setBlog({...blog, title: e.target.value})
                                                            console.log(e.target.value)
                                                        }} class="form-control" placeholder="Enter Blog Title" /> 
                                                        <small className="text-danger">{ errors.title ? errors.title[0] : '' }</small>
                                                    </div>
                                            </div>
                                            <div class="form-group">
                                                    <label class="form-label">Category:</label>
                                                    <div class="form-control-wrap">
                                                    <Select
                                                    value={categoryBlog}
                                                    onChange={handleCategoryChange}
                                                    options={categories}
                                                    />
                                                        {/* <small className="text-danger">{ errors.name ? errors.name[0] : '' }</small> */}
                                                    </div>
                                            </div>
                                            <div class="form-group">
                                                    <label class="form-label">Content:</label>
                                                    <div class="form-control-wrap">
                                                        <ReactQuill
                                                        style={{height: "30vh"}}
                                                        theme="snow" 
                                                        value={content} 
                                                        onChange={setContent}
                                                        modules={modules}
                                                        formats={formats} />
                                                        <small className="text-danger">{ errors.content ? errors.content[0] : '' }</small>
                                                    </div>
                                            </div>
                                        </div>
                                            <div className='m-2 p-2 d-flex justify-content-end'>
                                            <button disabled={isLoading} 
                                            className='btn btn-sm btn-outline-prim mx-1' 
                                            onClick={handleSaveBlog}><IoSaveSharp />Save</button>
                                            <button disabled={isLoading}
                                            className='btn btn-sm btn-outline-second mx-1' 
                                            onClick={handleBlogModelCancel}>Cancel</button>
                                            </div>
                                    </div>
                                    {isMetaDataExpand && <div class={`card shadow border-0 ${isMetaDataExpand && 'col-4'}`}>
                                        <div class="card-body">
                                                <div class="form-group">
                                                        <label class="form-label">Author Name</label>
                                                        <div class="form-control-wrap">
                                                        <input type="text" value={metadata.meta_title} 
                                                        onChange={e => setMetadata({...metadata, meta_title: e.target.value})} 
                                                        class="form-control" placeholder="Enter Author Name" /> 
                                                        <small className="text-danger">{ errors.meta_title ? errors.meta_title[0] : '' }</small>
                                                        </div>
                                                </div>
                                                <div class="form-group">
                                                    <label class="form-label">Meta Description</label>
                                                    <div class="form-control-wrap">
                                                            <input type="text" value={metadata.meta_description} 
                                                            onChange={e => setMetadata({...metadata, meta_description: e.target.value})} 
                                                            class="form-control" placeholder="Enter Metadata Description" /> 
                                                            <small className="text-danger">{ errors.meta_description ? errors.meta_description[0] : '' }</small>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                        <label class="form-label">Meta Tags</label>
                                                        <div class="form-control-wrap">
                                                            <input type="text" value={metadata.meta_tags} 
                                                            onChange={e => setMetadata({...metadata, meta_tags: e.target.value})} 
                                                            class="form-control" placeholder="Enter Meta tags" /> 
                                                            <small className="text-danger">{ errors.meta_tags ? errors.meta_tags[0] : '' }</small>
                                                        </div>
                                                </div>
                                            <div className='m-2 p-2 d-flex justify-content-end'>
                                            <button className='btn btn-sm btn-outline-second mx-1' 
                                            onClick={()=>handleCloseMetaModel()}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>)}

                        {(!metaModel&& categoryModel) && (
                            <>
                            <div class="nk-content-body">
                            <div class="nk-block-head-content d-flex justify-content-between align-items-center">
                                <div className="m-2">
                                    <h2 class="display-6">Blogs Categories</h2>
                                    <p>Manage Categories</p>
                                </div>
                            </div>
                            <div class="nk-block container">
                                <div className='row justify-content-between'>
                                    <div class={`card shadow border-0`}>
                                                <div class="form-group">
                                                        <label class="form-label">Category Name</label>
                                                        <div class="form-control-wrap">
                                                        <input type="text" value={category.name || category.category} 
                                                        onChange={e => setCategory({...category, name: e.target.value, category: e.target.value})} 
                                                        class="form-control" placeholder="Enter Category" /> 
                                                        <small className="text-danger">{ errors.name ? errors.name[0] : '' }</small>
                                                        </div>
                                                </div>
                                                    <div className="table-container" style={{height: "50vh", overflowY: "scroll", overflowX: "hidden"}}>
                                                    <table className='col-12 m-2'>
                                                        <thead>
                                                        <tr>
                                                            <th className='col-4'>ID</th>
                                                            <th className='col-4'>Name</th>
                                                            <th className='col-4'>Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {editableCategories.length > 0 ? editableCategories.map((item) => {
                                                            return(
                                                            <tr key={item.id}>
                                                            <td>{item.id}</td>
                                                            <td>{item.category}</td>
                                                            {!(deleteCatetoryConfirm === item.id) ?
                                                            <td>
                                                                <button
                                                                onClick={()=>handleEditCategory(item)}
                                                                disabled={isLoading}
                                                                className='btn btn-md btn-outline-primary m-1'
                                                                ><MdEdit />Edit</button>
                                                                <button 
                                                                // onClick={handleDeleteCategory}
                                                                onClick={()=>setDeleteCategoryConfirm(item.id)}
                                                                disabled={isLoading}
                                                                className='btn btn-md btn-outline-danger m-1'>
                                                                <MdDeleteOutline />Delete</button>
                                                            </td>
                                                            :
                                                            <td className='d-flex flex-column'>
                                                                <div>
                                                                    <button
                                                                    onClick={()=>handleDeleteCategory(item.id)}
                                                                    disabled={isLoading}
                                                                    className='btn btn-md btn-outline-primary m-1'
                                                                    ><IoCheckmark />Confirm</button>
                                                                    <button 
                                                                    onClick={()=>setDeleteCategoryConfirm(0)}
                                                                    disabled={isLoading}
                                                                    className='btn btn-md btn-outline-danger m-1'>
                                                                    <RxCross2 />Cancel</button>
                                                                </div>
                                                            </td>
                                                            }
                                                            </tr>
                                                        )}) : <p>No Categories</p>}
                                                        </tbody>
                                                    </table>
                                                    </div>
                                            {!(deleteCatetoryConfirm == 0) && <small className='text-danger'>Delete a category will also delete related Blogs</small>}
                                            <div className='m-2 p-2 d-flex justify-content-end'>
                                            <button disabled={isLoading}
                                            className='btn btn-sm btn-outline-prim mx-1' 
                                            onClick={handleSaveCatogery}><IoSaveSharp />Save</button>
                                            <button disabled={isLoading}
                                            className='btn btn-sm btn-outline-second mx-1'
                                            onClick={handleCategoryModel}>Cancel</button>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            </>
                        )}

                    </div>): <PageLoader/>}
                </div>
            </div>
        </>
    );
}

export default BlogEditor;