import { useState,useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import Helpers from "../Config/Helpers";
import { RiMenu3Fill } from "react-icons/ri";
import axios from "axios";

import React from "react";

const App = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
};

const Layout = () => {
  const [buttons, setButtons] = useState([]);
  const location = useLocation();

  const getButtons = () => {
    axios.get(`${Helpers.apiUrl}button/all`, Helpers.authHeaders).then(response=>{
      setButtons(response.data.buttons);
    }).catch(error=>{
      Helpers.toast("error", error.response.message);
    })
  }

  useEffect(() => {
    Helpers.loadScript("jquery.js")
      .then(() => Helpers.loadScript("bootstrap.min.js"))
      .then(() => Helpers.loadScript("owl.js"))
      .then(() => Helpers.loadScript("wow.js"))
      .then(() => Helpers.loadScript("jquery.fancybox.js"))
      .then(() => Helpers.loadScript("appear.js"))
      .then(() => Helpers.loadScript("isotope.js"))
      .then(() => Helpers.loadScript("jquery.nice-select.min.js"))
      .then(() => Helpers.loadScript("aos.js"))
      .then(() => Helpers.loadScript("plugins.js"))
      .then(() => Helpers.loadScript("script.js"))
      .catch((error) => console.error("Script loading failed: ", error));
  }, []);

  useEffect(()=>{
    getButtons();
  },[])

  useEffect(() => {
    Helpers.toggleCSS();
  }, [location.pathname]);
  return (
    <div class="boxed_wrapper ltr">
      <header class="main-header header-style-six">
        <div class="header-lower">
          <div class="auto-container">
            <div class="outer-box">
              <div class="logo-box">
                <figure class="logo">
                  <Link to="/" className="w100px">
                    <img
                      src="app/logo.png"
                      className="w100px logo-shadow"
                      alt=""
                    />
                  </Link>
                </figure>
              </div>
              <div class="menu-area">
                <div class="mobile-nav-toggler">
                  <RiMenu3Fill fill="white" size={"2rem"}/>
                </div>
                <nav class="main-menu navbar-expand-md navbar-light clearfix">
                  <div
                    class="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul class="navigation clearfix">
                      <li class="">
                        <Link to="/">Home</Link>
                      </li>
                      <li class="">
                        <Link to="/Screen/Features">Features</Link>
                      </li>
                      <li class="">
                        <Link to="/user/dashboard">Legal Assistant</Link>
                      </li>
                      <li class="">
                        <Link to="/Screen/Pricing">Pricing</Link>
                      </li>
                      <li class="">
                        <Link to="/Screen/Donation">Donation</Link>
                      </li>
                      <li class="">
                        <Link to="/Screen/Blogs">Blogs</Link>
                      </li>
                      <li class="">
                        <Link to="/Screen/About">About</Link>
                      </li>
                      <li class="">
                        <Link to="/Screen/Contact">Contact</Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div class="menu-right-content">
                <div class="btn-box">
                  {buttons.length > 0 ? buttons.map((btn)=>{
                    if(btn.name ==  "Top Navbar Button"){
                      return(
                        <Link to={btn.button_link} class="theme-btn btn-one">
                          {btn.button_text}
                        </Link>
                      );
                    }
                  })
                  : 
                  <Link to="/user/dashboard" class="theme-btn btn-one">
                    Get Started
                  </Link> }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sticky-header">
          <div class="auto-container">
            <div class="outer-box">
              <div class="logo-box">
                <figure class="logo">
                  <Link to="/">
                    <img
                      src="app/logo.png"
                      className="logo-shadow"
                      alt=""
                      width={"80px"}
                    />
                  </Link>
                </figure>
              </div>
              <div class="menu-area">
                <nav class="main-menu clearfix"></nav>
              </div>
              <div class="menu-right-content">
                <div class="search-box-outer search-toggler mr_30">
                  <i class="icon-1"></i>
                </div>
                <div class="btn-box">
                {buttons.length > 0 ? buttons.map((btn)=>{
                    if(btn.name ==  "Top Navbar Button"){
                      return(
                        <Link to={btn.button_link} class="theme-btn btn-one">
                          {btn.button_text}
                        </Link>
                      );
                    }
                  })
                  : 
                  <Link to="/user/dashboard" class="theme-btn btn-one">
                    Get Started
                  </Link> }
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div class="mobile-menu">
        <div class="menu-backdrop"></div>
        <div class="close-btn">
          <i class="fas fa-times"></i>
        </div>

        <nav class="menu-box">
          <div class="nav-logo">
            <Link to="/">
              <img
                className="logo-shadow"
                src="app/logo-white.png"
                alt=""
                title=""
              />
            </Link>
          </div>
          <span class="menu-outer"></span>
          <div class="social-links">
            <ul class="clearfix">
              <li>
                <Link to="/">
                  <span class="fab fa-twitter"></span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <span class="fab fa-facebook-square"></span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <span class="fab fa-pinterest-p"></span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <span class="fab fa-instagram"></span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <span class="fab fa-youtube"></span>
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <Outlet />

      <footer class="main-footer">
        <div class="auto-container">
          <div class="widget-section">
            <div class="row clearfix">
              <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                <div class="footer-widget logo-widget">
                  <figure class="footer-logo">
                    <Link to="/">
                      <img
                        className="logo-shadow"
                        src="app/logo-white.png"
                        alt=""
                      />
                    </Link>
                  </figure>
                  {/* <p>Our cutting-edge technology harnesses the power of artificial intelligence to provide swift and accurate answers to your legal queries</p> */}
                  {/* <ul class="social-links clearfix">
                                        <li><Link to="/"><i class="fa-brands fa-facebook"></i></Link></li>
                                        <li><Link to="/"><i class="fa-brands fa-twitter"></i></Link></li>
                                        <li><Link to="/"><i class="fa-brands fa-linkedin"></i></Link></li>
                                        <li><Link to="/"><i class="fa-brands fa-dribbble"></i></Link></li>
                                    </ul> */}
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                <div class="footer-widget links-widget ml_60">
                  <div class="widget-title">
                    <h3>Resources</h3>
                  </div>
                  <div className="widget-content">
                    <ul className="links-list clearfix">
                      <li>
                        <Link to="/Screen/Features">Features</Link>
                      </li>
                      <li>
                        <Link to="/user/dashboard">Legal Assistant</Link>
                      </li>
                      <li>
                        <Link to="/Screen/About">About</Link>
                      </li>
                      <li>
                        <Link to="/Screen/Contact">Contact</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                <div class="footer-widget links-widget">
                  <div class="widget-title">
                    <h3>Primary Pages</h3>
                  </div>
                  <div class="widget-content">
                    <ul class="links-list clearfix">
                      <li>
                        <Link to="/">Privacy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                                <div class="footer-widget subscribe-widget">
                                    <div class="widget-title">
                                        <h3>Subscribe Newsletter</h3> 
                                    </div>
                                    <div class="widget-content">
                                        <p>Combined with a handy platform and top-notch support from our in-house production team</p>
                                        <div class="form-inner">
                                            <form method="post" action="https://azim.hostlin.com/Sensai/contact.html">
                                                <div class="form-group">
                                                    <input type="email" name="email" placeholder="Email Address" required />
                                                    <button type="submit" class="theme-btn btn-one">Subscribe</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
        <div class="footer-bottom centred">
          <div class="auto-container">
            <div class="copyright">
              <p>
                Copyright &copy; 2024 <Link to="/">LegalMaster.AI</Link>. All
                Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </footer>

      <div class="scroll-to-top">
        <div>
          <div class="scroll-top-inner">
            <div class="scroll-bar">
              <div class="bar-inner"></div>
            </div>
            <div class="scroll-bar-text">Go To Top</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
