import { useContext, useEffect, useRef, useState } from "react";
import { ChatsContext } from "../../Contexts/ChatsContext";
import { useParams } from "react-router-dom";
import Helpers from "../../Config/Helpers";
import axios from "axios";
import Message from "../../Components/Message";
import AskAnything from "../../Components/AskAnything";
import NavbarContext from "../../Contexts/NavbarContext";
import { useNavigate } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import ExpirationAlert from '../../Components/ExpirationAlert';

const Chatbot = () => {
    const navigate = useNavigate();
    const {chat_id} = useParams();
    const messagesEndRef = useRef(null);
    const { setChats, showChat } = useContext(ChatsContext);
    const Navbar = useContext(NavbarContext);
    const [limitModel, setLimitModel] = useState(false);
    const [isLogged, setLogged] = useState(false)
    const [messages, setMessages] = useState([]);
    const [hasSideBar, setHasSideBar] = useState(Navbar.state.hasSideBar);
    const [message, setMessage] = useState("");
    const [chat, setChat] = useState({});
    const [writing, setWriting] = useState(false);
    const [errCode, setErrCode] = useState();

    const getChat = () => {
        let msg = Helpers.getItem("message");
        localStorage.removeItem("message");
        if(!msg){
            axios.get(`${Helpers.apiUrl}chat/single/${chat_id}`, Helpers.authHeaders).then(response => {
                if(response.data){
                    setChat(response.data.chat);
                    setChats(response.data.chats);
                    if(response.data.chat){
                        setMessages(response.data.chat.messages);
                    }
                    setTimeout(() => {
                        scrollToBottom();
                    }, 500);
                }
            });
        }else{
            setChat({
                user_id: Helpers.getItem("id"),
                is_guest: Helpers.getItem("user") ? 0 : 1,
                chatId: chat_id,
                title: msg,
            });
            axios.get(`${Helpers.apiUrl}chat/all/${Helpers.getItem("id")}`, Helpers.authHeaders).then(res => {
                setChats(res.data);
            });
            getResponse(msg);
        }
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth", top: messagesEndRef.current.scrollHeight, block:"end" })
    }

    const getResponse = (prompt = "") => {
        if(prompt || message){
            setWriting(true);
            addMessage(prompt ? prompt : message);
            setTimeout(() => {
                scrollToBottom();
            }, 500);
            const data = {
                chatId: chat_id,
                message: prompt ? prompt : message,
                user_id: Helpers.getItem("id"),
                is_guest: Helpers.getItem("user") ? 0 : 1,
            };
            addMessage("", 1);
            setMessage("");
            const controller = new AbortController();
            const signal = controller.signal;
            fetch(`${ Helpers.apiUrl }chat/response`, {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                },
                body: JSON.stringify(data),
                signal
            }).then(response => {
                if(!response.ok){
                    response.json().then(error => {
                        Helpers.toast("error", error.message);
                        console.log(error);
                        setWriting(false);
                        setTimeout(() => {
                            scrollToBottom();
                        }, 500);
                        if(error.code == 501 || error.code === 503 ){
                            if(error.code == 503){
                                setLogged(true);
                            }
                            setLimitModel(true);
                            setErrCode(error.code)
                        }
                    });
                }else{
                    const reader = response.body.getReader();
                    const decoder = new TextDecoder();
        
                    function processText({done, value}){
                        if(done){
                            setWriting(false);
                            axios.get(`${Helpers.apiUrl}chat/all/${Helpers.getItem("id")}`, Helpers.authHeaders).then(res => {
                                setChats(res.data);
                            });
                            return;
                        }
                        let text = decoder.decode(value);
                        if(text.endsWith("[DONE]")){
                            text = text.slice(0, -6);
                        }
                        let withLines = text.replace(/\\n/g, '\n');
                        setMessages(prevMessages => {
                            const updatedMessages = [...prevMessages];
                            updatedMessages[messages.length - 1].message += withLines;
                            return updatedMessages;
                        });
                        setTimeout(() => {
                            scrollToBottom();
                        }, 500);
                        reader.read().then(processText);
                    }
                    reader.read().then(processText);
                }
            }).catch(error => {
                console.log("ERROR::", error);
                setWriting(false);
            });
        }else{
            Helpers.toast("error", "Can't send without input");
        }
    }

    const handleCloseLimitModel = () => {
        if(errCode == 501){
            navigate('/Screen/Donation');
        }else{
            navigate('/user/pricing');
        }
    }

    const addMessage = (message = "", is_bot = 0, is_hidden = 0, is_included = 1) => {
        let alls = messages;
        let msg = {message, is_bot, is_hidden, is_included};
        alls.push(msg);
        setMessages(alls);
    }

    useEffect(() => {
        getChat();
    }, [chat_id]);

    useEffect(()=>{
        setHasSideBar(Navbar.state.hasSideBar)
    }, [Navbar.state.hasSideBar])

    return (
        <div className={`tyn-main w-100 ${showChat ? 'main-shown' : ''} ${hasSideBar ? " " :  "col-md-12"}`} id="tynMain"  
        style={
          hasSideBar 
          ? 
            { 
            background: "#F8F8F8"
            }
            :
            { 
            background: "#F8F8F8",  
            bottom: "0",
            important: "true",
            position: messages.length > 0 ? "static" : "absolute"
            }} 
          >
            <div className="mt-2 mx-4">
                <ExpirationAlert />
            </div>
            <div className="tyn-chat-body my-4 px-4 js-scroll-to-end" id="tynBotBody"  style={{ background: "#F8F8F8" }}>
                <div className="container px-0">
                    <div className="tyn-qa tyn-qa-bubbly mb-3" id="tynBotReply">
                        {messages.map((message, index) => <Message key={index} isWriting={(messages.length === index + 1) ? writing : false} message={message.message} isBot={message.is_bot} />)}
                    </div>
                    <div ref={messagesEndRef} />
                </div>
            </div>
            
            <AskAnything getReply={getResponse} message={message} setMessage={setMessage} isLoading={writing} />

            <Modal show={limitModel} onHide={handleCloseLimitModel}>
                <Modal.Header closeButton>
                <Modal.Title>Limit Reached</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                Your plan has expired. Kindly {isLogged ? "Subscribe ": "Donate"} to Continue.
                </Modal.Body>
                <button className="btn btn-prim w-25 mx-auto m-4" onClick={handleCloseLimitModel}>Continue</button>
            </Modal>
            
        </div>
    );
}

export default Chatbot;