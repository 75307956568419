import React from 'react'
import { useRef } from 'react';
import styles from '../../CSS/Mockup-Trial.module.css';

export default function ChatInput({ getReply, message, setMessage, isLoading = false }) {
    const textareaRef = useRef(null);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            getReply();
            setMessage('')
        }
    };


    const handleInputChange = e => {
        const msg = e.target.value;
        setMessage(msg)
    }

    const submitForm = e => {
        e.preventDefault();
        getReply();
        setMessage('')
    }

    return (
        <div className={`${styles.border_chatInput}`}>
            <div className="container px-0">
                <div
                >
                    <form onSubmit={getReply}>
                        <div className="w-100 d-flex flex-row justify-content-center align-items-center">
                            <textarea ref={textareaRef}
                                className={`${styles.ChatInput}`}
                                rows={1} value={message} type="text"
                                onKeyDown={handleKeyPress} onChange={handleInputChange}
                                placeholder="Send a message" disabled={isLoading}
                                style={{ border: 'none', resize: 'none' }}></textarea>
                            <ul className="">
                                <li>
                                    <button disabled={!message} onClick={submitForm} className="btn btn-custom border-0">
                                        {isLoading ? <>
                                            <i className="fa-duotone fa-spinner-third fa-spin text-white"></i>
                                        </> : <>
                                            <i className="fa-light fa-paper-plane text-white"></i>
                                        </>}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
