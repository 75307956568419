import { useState } from "react";
import { Link, json } from "react-router-dom";
import axios from "axios";
import Helpers from "../../Config/Helpers";
import { IoIosArrowBack } from "react-icons/io";

const Login = () => {
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [isLoading, setIsLoading] = useState(false)

  const [errors, setErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const handleLogin = (event) => {
    event.preventDefault();
    setErrors({});
    setIsLoading(true)
    axios
      .post(`${Helpers.apiUrl}auth/login`, loginData)
      .then((response) => {
        Helpers.setItem("user", response.data.user, true);
        Helpers.setItem("token", response.data.token);
        Helpers.setItem("id", response.data.user.id);
        let currentTime = new Date().getTime();
        Helpers.setItem("loginTimestamp", currentTime);

        if (response.data.token) {
          if(response.data.user.user_type){
            window.location.href = "/admin/dashboard";
          }else{
            window.location.href = "/user/dashboard";
          }
        } else {
          window.location.href = "/auth/register";
        }
        setIsLoading(false)
      })
      .catch((error) => {
        if (error.response) {
          setErrors(error.response.data.errors || {});
          Helpers.toast("error", error.response.data.message);
        }
        setIsLoading(false)
      });
  };

  return (
    <div class="tyn-root " style={{ background: '#F8F8F8' }}>

      {/* <Link
      to={'/'} 
      className="theme-btn btn-two d-flex justify-content-center align-items-center" 
      style={{position: "absolute", top: "5%", left: "5%"}}>
        <IoIosArrowBack /> 
        <span >Go Back</span>
      </Link> */}

      <div class="tyn-content tyn-auth tyn-auth-centered d-flex justify-content-center align-items-center"  style={{minHeight: '100vh', overflowY: "hidden"}}>
        <div class="container">
          <div class="row justify-content-center" >
            <div class="col-xl-4 col-lg-5 col-md-7 col-sm-9"  style={{ background: 'White', padding: '30px', borderRadius: '10px' }}>
              <div class="my-3 text-center">
                <img src="	https://legalmasterai.com/app/Legaldrafting.png" className="w200" />
              </div>
              <form onSubmit={handleLogin} >
                <div className="form-group" >
                  <label
                    htmlFor="email-address"
                    className="form-label white-sub"
                  >
                    Email Address
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control "
                      id="email-address"
                      name="email"
                      placeholder="youremail@example.com"
                      value={loginData.email}
                      onChange={handleInputChange}
                    />
                    <small className="text-danger">
                      {errors.email ? errors.email[0] : ""}
                    </small>
                  </div>
                </div>

                <div className="form-group">
                  <label
                    htmlFor="password"
                    className="form-label d-flex white-sub"
                  >
                    Password
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="password"
                      className="form-control "
                      id="password"
                      name="password"
                      placeholder="password"
                      value={loginData.password}
                      onChange={handleInputChange}
                    />
                    <small className="text-danger">
                      {errors.password ? errors.password[0] : ""}
                    </small>
                  </div>
                </div>

                <div className="form-group pt-2">
                  <button type="submit" className="btn w-100" style={{ background: "#0C1B44" , color : 'white' }} disabled={isLoading}>
                    Account Login
                  </button>
                </div>
              </form>
            </div>
          </div>
      <div class="text-center mt-4">
        <p class="small white-sub">
          Don't have an account?{" "}
          <Link className="color-primary" to="/auth/register">
            Create a Free Account
          </Link>
        </p>
      </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
