// PageLoader.js
import React from 'react';
import loaderGif from './balance.gif'; // Import the GIF file

const PageLoader = ({text}) => {
  return (
    <div style={{ display: 'flex',flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <img src={loaderGif} alt="Loading..." style={{ height: '100px'}} />
      {text && (<p className='text-center'>{text}</p>)}
    </div>
  );
};

export default PageLoader;
