import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const FormComponent = ({ initialQuestion, onFormSubmit }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    country: "",
    state: "",
    plaintiffOrDefendant: "",
    details: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [field]: null }));
  };

  const validateForm = () => {
    const validationErrors = {};

    if (!formData.country.trim()) {
      validationErrors.country = ["Country is required"];
    }

    if (!formData.state.trim()) {
      validationErrors.state = ["State is required"];
    }

    if (!formData.plaintiffOrDefendant.trim()) {
      validationErrors.plaintiffOrDefendant = ["Role is required"];
    }

    if (!formData.details.trim()) {
      validationErrors.details = ["Details are required"];
    }

    setErrors(validationErrors);

    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValid = validateForm();

    if (isValid) {
      if (typeof onFormSubmit === "function") {
        onFormSubmit(formData);
      } else {
        console.error("onFormSubmit is not a function");
      }

      setFormData({
        country: "",
        state: "",
        plaintiffOrDefendant: "",
        details: "",
      });

      localStorage.setItem("formData", JSON.stringify(formData));

      navigate("/user/chat/${chatId}`");
    }
  };

  return (
    <div className="tyn-root" style={{ background: "#FFFFFF" }}>
  <div className="tyn-content tyn-auth tyn-auth-centered">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-6 col-lg-8">
          <div className="my-3 text-center">
            <img src="/app/Legaldrafting.png" className="w200" alt="Logo" />
          </div>
          <div
            className="card border-0 card-custom"
            style={{
              background: "White",
              color: "#000000", // Black text
              border: "2px solid #000000", // Black border
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div className="p-4">
              <h3 className="black">Submit Form</h3>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="form-label white-sub" htmlFor="country">
                    <span className="text-black">Which Country do you live in?</span>
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      id="country"
                      className={`form-control  ${
                        errors.country ? "is-invalid" : ""
                      }`}
                      onChange={(e) =>
                        handleInputChange("country", e.target.value)
                      }
                      placeholder="Enter your country"
                    />
                    {errors.country && (
                      <small className="text-danger">{errors.country[0]}</small>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label white-sub" htmlFor="state">
                    <span className="text-black">Which state do you live in?</span>
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      id="state"
                      className={`form-control  ${
                        errors.state ? "is-invalid" : ""
                      }`}
                      value={formData.state}
                      onChange={(e) =>
                        handleInputChange("state", e.target.value)
                      }
                      placeholder="Enter your state"
                    />
                    {errors.state && (
                      <small className="text-danger">{errors.state[0]}</small>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <label
                    className="form-label white-sub"
                    htmlFor="plaintiffOrDefendant"
                  >
                    <span className="text-black">Are you a plaintiff or a defendant?</span>
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      id="plaintiffOrDefendant"
                      className={`form-control  ${
                        errors.plaintiffOrDefendant ? "is-invalid" : ""
                      }`}
                      value={formData.plaintiffOrDefendant}
                      onChange={(e) =>
                        handleInputChange(
                          "plaintiffOrDefendant",
                          e.target.value
                        )
                      }
                      placeholder="Enter your role"
                    />
                    {errors.plaintiffOrDefendant && (
                      <small className="text-danger">
                        {errors.plaintiffOrDefendant[0]}
                      </small>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label white-sub" htmlFor="details">
                    <span className="text-black">
                      Can you describe in detail what happened, who did it, and when these things were done?
                    </span>
                  </label>
                  <div className="form-control-wrap">
                    <textarea
                      id="details"
                      className={`form-control ${
                        errors.details ? "is-invalid" : ""
                      }`}
                      value={formData.details}
                      onChange={(e) =>
                        handleInputChange("details", e.target.value)
                      }
                      placeholder="Enter details here"
                    />
                    {errors.details && (
                      <small className="text-danger">{errors.details[0]}</small>
                    )}
                  </div>
                </div>

                <div className="col-12 p-2">
                  <button type="submit" className="btn w-100"   style={{ background: "#0C1B44" }}>
                  <span className="text-white">  Submit</span> 
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  

  );
};

export default FormComponent;
